import { useState, useEffect } from "react";
import "./FooterUpper.scss";
import { useNavigate } from "react-router-dom";
import { isCandidate, isLoggedIn } from "@/utils/authUtil";
import * as storageConstants from "@/constants/storageConstants";
import * as commonServices from "@/services/common";
import ScrollToTop from "react-scroll-to-top";
import { useLocation } from "react-router-dom";
import FooterHome from "./FooterHome";
import { momentYear } from "../../utils/dateUtil";
import Button from "../_widgets/Button/Button";
import IconResource from "../_widgets/IconResource/IconResource";
import Text from "../_widgets/Text/Text";

const allowedFixedFooter = [];

function FooterUpper({ isMasterDataLoaded }) {
  const navigate = useNavigate();
  var masterData = commonServices.getLocalData(storageConstants.DATA);
  const [data, setData] = useState(
    masterData !== null &&
      masterData.system_config !== null &&
      masterData.system_config !== undefined
      ? masterData.system_config
      : {}
  );
  const location = useLocation();

  useEffect(() => {
    masterData = commonServices.getLocalData(storageConstants.DATA);
    setData(
      masterData !== null &&
        masterData.system_config !== null &&
        masterData.system_config !== undefined
        ? masterData.system_config
        : {}
    );
  }, [isMasterDataLoaded]);

  const handleClick = (e) => {
    const currentClass = document.getElementsByClassName("naving");
    for (let i = 0; i < currentClass.length; i++) {
      if (typeof currentClass[i] !== "undefined")
        currentClass[i].classList.remove("active");
    }
    if (typeof currentClass[e] !== "undefined")
      currentClass[e].classList.add("active");
  };
  const [QrCode, setQrCode] = useState(true);

  return (
    <div
      className={
        "footer_upper " +
        (allowedFixedFooter.includes(location.pathname) ? " footer_fixed" : " ")
      }
    >
      <ScrollToTop
        smooth
        color="#14BC9A"
        svgPath="M6.86813 0.470885C7.01671 0.321607 7.19315 0.203184 7.38736 0.122386C7.58158 0.0415875 7.78976 -3.58881e-07 8 -3.49691e-07C8.21024 -3.40501e-07 8.41842 0.0415876 8.61264 0.122386C8.80685 0.203184 8.9833 0.321607 9.13188 0.470885L15.5312 6.89308C15.8314 7.19435 16 7.60295 16 8.02901C16 8.45506 15.8314 8.86367 15.5312 9.16494C15.231 9.4662 14.8238 9.63545 14.3993 9.63545C13.9748 9.63545 13.5676 9.4662 13.2674 9.16494L9.59982 5.48221L9.59982 22.3945C9.59982 22.8203 9.43127 23.2286 9.13124 23.5297C8.83122 23.8308 8.4243 24 8 24C7.5757 24 7.16878 23.8308 6.86875 23.5297C6.56873 23.2286 6.40018 22.8203 6.40018 22.3945L6.40018 5.48221L2.73259 9.16494C2.58395 9.31411 2.40749 9.43244 2.21328 9.51317C2.01907 9.5939 1.81092 9.63545 1.60071 9.63545C1.3905 9.63545 1.18235 9.5939 0.988147 9.51317C0.79394 9.43244 0.617478 9.31411 0.468838 9.16494C0.320198 9.01576 0.202291 8.83867 0.121848 8.64377C0.0414043 8.44886 -9.51325e-07 8.23997 -9.42103e-07 8.02901C-9.32882e-07 7.81805 0.0414044 7.60915 0.121848 7.41425C0.202291 7.21935 0.320198 7.04225 0.468838 6.89308L6.86813 0.470885Z"
        viewBox="0 0 16 24"
      />
      <a
        href={"https://wa.me/" + process.env.REACT_APP_WHATSAPP_NUMBER}
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>

      {QrCode &&
        location.pathname === "/" &&
        (!isLoggedIn() || isCandidate()) ? (
        <>
          <div className="qr-code-image-box">
            <div className="qr-code-image">
              <Text text="Download App" type="small" />
              <img src="/Assets/images/qrcode.png" width={80} alt='QrCode' />
              <Button className="cross-btn" onClick={() => setQrCode(false)} buttonIcon={<IconResource type="cross" FontSize={18} />} />
            </div>
          </div>
        </>
      ) : null}
      <footer>
        <div className="row tt-container div_footer py-1">
          {location.pathname === "/" ? (
            <FooterHome />
          ) : (
            <div className="col-12 ps-0 ">
              <ul className="nav">
                <li className="nav-item">
                  <a
                    href={'/'}
                    onClick={() => {
                      navigate("/");
                      handleClick(0);
                    }}
                    className="nav-link p-0 hand-hover"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href={"/about"}
                    onClick={() => {
                      navigate("/about");
                      handleClick(1);
                    }}
                    className="nav-link p-0 hand-hover"
                  >
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href={"/contact"}
                    className="nav-link p-0 hand-hover"
                  >
                    Contact Us
                  </a>
                </li>
                {/* <li className="nav-item"><a href={'/giveaway'} className="nav-link p-0 hand-hover">Giveaway</a></li> */}
                <li className="nav-item">
                  <a
                    target="_blank"
                    href={'/blog'}
                    className="nav-link p-0 hand-hover"
                  >
                    Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href={'/terms'}
                    className="nav-link p-0 hand-hover"
                  >
                    Terms of Use & Privacy Policy
                  </a>
                </li>
                {/* <li className="nav-item mx-2"><a href='/privacy' target="_blank" className="nav-link p-0 ">Privacy Policy</a></li> */}
              </ul>
            </div>
          )}
        </div>
        <div className="tt-container px-0 d-flex justify-content-center py-1 footer_btm">
          <div className="row div_footer">
            <div className="col-6">
              <p className="text-muted">
                All rights reserved © {momentYear()} FindrPro Technology
                Solutions Pvt. Ltd.
              </p>
            </div>
            <div className="col-6 ml-auto div_f_c">
              <p className="text-muted">Stay Connected</p>
              <ul className="icons">
                {commonServices.checkRecordExists(data?.fb?.value) ? (
                  <li>
                    <a target="_blank" href={data?.fb?.value}>
                      <i className="bi bi-facebook"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {commonServices.checkRecordExists(data?.linkedin?.value) ? (
                  <li>
                    <a target="_blank" href={data?.linkedin?.value}>
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {commonServices.checkRecordExists(data?.twitter?.value) ? (
                  <li>
                    <a target="_blank" href={data?.twitter?.value}>
                      <i className="bi bi-twitter"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {commonServices.checkRecordExists(data?.instagram?.value) ? (
                  <li>
                    <a target="_blank" href={data?.instagram?.value}>
                      <i className="bi bi-instagram"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {commonServices.checkRecordExists(data?.mail?.value) ? (
                  <li>
                    <a href={undefined}>
                      <i className="bi bi-envelope-fill"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}

                {commonServices.checkRecordExists(data?.youtube?.value) ? (
                  <li>
                    <a target="_blank" href={data?.youtube?.value}>
                      <i className="bi bi-youtube"></i>
                    </a>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default FooterUpper;
