
import React, { useState } from 'react';
import './Modal.scss'
import { css } from "@emotion/react";
import deleteAccount from '@/Assets/images/deleteAccount.png';
import AppModal from '../Modals/AppModal';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function ModalRestoreAccount({ showModal, setShowModal, restoreAccount }) {
    let [loading] = useState(false);
    let [color] = useState("#000000");

    return (
        <>
            <AppModal
                title={"Account Deleted"}
                isOpen={showModal}
                closeModal={() => { setShowModal(prev => !prev); }}
                submit={restoreAccount}
                isFooter={true}
                submitText={"Restore"}
                cancelText={"Exit"}
                size={"sm"}
            >

                <div className="modal_body">
                    <div className="row info-content">
                        <p>Your account has been deleted, but it can still be restored.</p>
                        <p>Note: After a period of 30 days from the initial request, your account will be permanently deleted. </p>
                    </div>
                </div>
            </AppModal>
        </>
    )
}

export default ModalRestoreAccount