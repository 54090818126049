import React from 'react';
import { useNavigate } from 'react-router'
import './Modal.scss';
import mobileImg from '../../Assets/images/popup-mobile.png'
import desktopImg from '../../Assets/images/popup-web.png'

import * as commonServices from '@/services/common';


function ModalGiveawayBanner({ showModal, setShowModal, popups }) {
    const isMobile = window.innerWidth <= 767; // You can adjust the threshold for what is considered "mobile"

    const navigate = useNavigate();
    var popupsData = popups;

    return (
        <>
            {showModal ? (
                <>

                    <div class="modal giveaway_modal">

                        <div className=" giveaway_modal_content">
                            <div className="modal_body giveaway_modal_body">

                                <div className=" giveaway-img">
                                    <img src={isMobile ? mobileImg : desktopImg} alt=''
                                        style={{ width: '100%', height: 'auto', }}
                                    />

                                    <button className='close-btn' onClick={() => setShowModal(prev => !prev)} ><img onError={commonServices.imgError} src="../../Assets/svgs/x.svg" alt="" /></button>
                                </div>

                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col">
                                        <div className='d-flex gap-3' >
                                            {popupsData?.button1Text ?
                                                <button className="btn btn-primary" onClick={() => { navigate(popupsData?.button1Action); }}
                                                    tyle={{ margin: "20px 0px !important", fontSize: "1em" }}
                                                >{popupsData?.button1Text}</button>
                                                : ''
                                            }
                                            {popupsData?.button2Text ?
                                                <button className="btn btn-primary" onClick={() => { navigate(popupsData?.button2Action); }}
                                                    tyle={{ margin: "20px 0px !important" }}
                                                >{popupsData?.button2Text}</button>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </>

            ) : null}
        </>
    )
}

export default ModalGiveawayBanner
