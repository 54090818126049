/* eslint-disable */
import React, { useState } from "react";
import "./Modal.scss";
import * as toast from "@/wrapper/toast";
import AppModal from "./AppModal";
import { useEffect } from "react";
import * as erros from "@/constants/stringConstants";
import * as ApiHelper from "@/services/apiHelper";
import { isCompanyAdmin } from "../../utils/authUtil";
import Button from "../_widgets/Button/Button";
import { preventPlusMinusInNumberInput } from "../../services/common";

function ModalBuyFeaturedGigSlots({
  showModal,
  setShowModal,
  data,
  getData,
  openBuyCashModal,
  setActiveTab,
}) {
  var homepageJobDateSlots = data?.homepageJobDateSlots;
  var searchpageJobDateSlots = data?.searchpageJobDateSlots;

  const [loading, setLoading] = useState(false);
  const [isHomepageSlots, setIsHomepageSlots] = useState(true);
  const [isSearchpageSlots, setIsSearchpageSlots] = useState(false);
  const [homepageSlots, setHomepageSlots] = useState(0);
  const [homepageSlotTime, setHomepageSlotTime] = useState("");
  const [searchpageSlotTime, setSearchpageSlotTime] = useState("");
  const [searchpageSlots, setSearchpageSlots] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [homepageSlotError, setHomepageSlotError] = useState("");
  const [searchpageSlotError, setSearchpageSlotError] = useState("");
  const [isSubmitDisabled, setIsSubmitDisbaled] = useState(false);

  const onSubmit = async (e) => {
    if (isHomepageSlots && homepageSlots < 1) {
      toast.error(erros.HOMEPAGE_SLOTS_REQUIRED);
    } else if (
      isHomepageSlots &&
      homepageSlots > 0 &&
      homepageSlotTime === ""
    ) {
      toast.error(erros.HOMEPAGE_SLOTS_TIME_REQUIRED);
    } else if (isSearchpageSlots && searchpageSlots < 1) {
      toast.error(erros.SEARCHPAGE_SLOTS_REQUIRED);
    } else if (
      isSearchpageSlots &&
      searchpageSlots > 0 &&
      searchpageSlotTime === ""
    ) {
      toast.error(erros.SEARCHPAGE_SLOTS_TIME_REQUIRED);
    } else {
      let formData = new FormData();
      formData.append("isHomepageSlots", isHomepageSlots);
      formData.append("isSearchpageSlots", isSearchpageSlots);
      formData.append("homepageSlots", homepageSlots);
      homepageSlotTime.forEach((item, index) => {
        formData.append(`homepageSlotTime[]`, item);
      });
      formData.append("searchpageSlots", searchpageSlots);
      searchpageSlotTime.forEach((item, index) => {
        formData.append(`searchpageSlotTime[]`, item);
      });
      setLoading(true);
      await ApiHelper.buyGigSlots(formData).then((response) => {
        if (response.isSuccess === true) {
          getData();
          setShowModal(false);
          toast.success(response.message);
        } else {
          toast.warn(response.message);
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (
      data?.homepage_feature_gig_slot_price !== undefined &&
      data?.searchpage_feature_gig_slot_price !== undefined
    ) {
      let amount =
        homepageSlots * homepageSlotTime.length * data?.homepage_feature_gig_slot_price +
        searchpageSlots * searchpageSlotTime.length * data?.searchpage_feature_gig_slot_price;
      setTotalAmount(amount);
    }
  }, [homepageSlotTime, searchpageSlotTime, homepageSlots, searchpageSlots, data]);

  const initializeValues = () => {
    setIsHomepageSlots(true);
    setIsSearchpageSlots(false);
    setHomepageSlots(0);
    setSearchpageSlots(0);
    setTotalAmount(0);
    setHomepageSlotTime([]);
    setSearchpageSlotTime([]);
    setHomepageSlotError("");
    setSearchpageSlotError("");
    setIsSubmitDisbaled(false);
  };

  useEffect(() => {
    if (showModal) {
      initializeValues();
    }
  }, [showModal]);

  const checkSlotsAvailability = async (page, slots, duration) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("page", page);
    formData.append("slots", slots);
    duration.forEach((item, index) => {
      formData.append(`durations[]`, item);
    });
    await ApiHelper.checkGigSlots(formData).then((response) => {
      if (response.isSuccess === true) {
        if (page === "homepage") {
          setHomepageSlotError("");
          if (searchpageSlotError === "") {
            setIsSubmitDisbaled(false);
          }
        } else {
          if (homepageSlotError === "") {
            setIsSubmitDisbaled(false);
          }
          setSearchpageSlotError("");
        }
      } else {
        setIsSubmitDisbaled(true);
        if (page === "homepage") {
          setHomepageSlotError(response.message);
        } else {
          setSearchpageSlotError(response.message);
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (homepageSlots > 0 && homepageSlotTime !== "") {
      checkSlotsAvailability("homepage", homepageSlots, homepageSlotTime);
    }
  }, [homepageSlots, homepageSlotTime]);

  useEffect(() => {
    if (searchpageSlots > 0 && searchpageSlotTime !== "") {
      checkSlotsAvailability("searchpage", searchpageSlots, searchpageSlotTime);
    }
  }, [searchpageSlots, searchpageSlotTime]);

  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (queryParams.get('action') === 'buy') {
      buyMoreCash();
    }
  }, []);

  const buyMoreCash = () => {
    if (isCompanyAdmin()) {
      setShowModal(false);
      setActiveTab("recruiters");
      openBuyCashModal();
    } else {
      toast.warn("Ask your Company Admin for more TT Cash");
    }
  };
  const toggleSlotSelection = (slot) => {
    if (homepageSlotTime.includes(slot)) {
      setHomepageSlotTime(homepageSlotTime.filter(selectedSlot => selectedSlot !== slot));
    } else {
      setHomepageSlotTime([...homepageSlotTime, slot]);
    }
  };

  const toggleSearchSlotSelection = (slot) => {
    if (searchpageSlotTime.includes(slot)) {
      setSearchpageSlotTime(searchpageSlotTime.filter(selectedSlot => selectedSlot !== slot));
    } else {
      setSearchpageSlotTime([...searchpageSlotTime, slot]);
    }
  };

  return (
    <>
      <AppModal
        title={"Buy Featured Gig Slots"}
        isOpen={showModal}
        closeModal={() => setShowModal((prev) => !prev)}
        submit={(e) => onSubmit(e)}
        size={"xl"}
        isFooter={true}
        isLoading={loading}
        submitText="Buy Slots"
      >
        <div className="modal_body" style={{ fontSize: "13px" }}>
          <div
            className="row feature-job-data"
            style={{
              border: "none",
              marginBottom: "0px !important",
              paddingBottom: "0px",
            }}
          >
            <div className="col">
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col check-div">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="isHomepageSlots"
                    id="isHomepageSlots"
                    defaultChecked={isHomepageSlots}
                    onChange={() => setIsHomepageSlots(!isHomepageSlots)}
                  />
                  <label className="form-check-label" for="isHomepageSlots">
                    Feature on Homepage -{" "}
                    {data?.homepage_feature_gig_slot_price} TT Cash / Slot
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="required" htmlFor="">
                    Enter Number of Slots
                  </label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend"></div>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Number of slots required"
                      name="homepageSlots"
                      min={0}
                      max={9}
                      onKeyDown={e => preventPlusMinusInNumberInput(e)}
                      value={homepageSlots}
                      onChange={(e) => e.target.value <= 9 && setHomepageSlots(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="times_wrapper">
                  {homepageJobDateSlots && homepageJobDateSlots.length > 0
                    ? homepageJobDateSlots.map((dateSlot, k) => {
                      var slot =
                        dateSlot?.start_date + "-" + dateSlot?.end_date;
                      return (
                        <label
                          key={"morning" + k}
                          className={`time_label hand-hover position-relative ${homepageSlotTime.includes(slot) ? 'active-slot' : ''}`}
                          style={{ flexDirection: "column", display: "flex", alignItems: "center" }}
                        >
                          <input
                            type="checkbox"
                            style={{ display: "none" }} // Hide the default checkbox
                            name={"slot" + k}
                            checked={homepageSlotTime.includes(slot)}
                            onChange={() => toggleSlotSelection(slot)} // Handle selection change
                          />
                          <div className="custom-checkbox"></div> {/* Custom checkbox design */}
                          <p
                            style={{
                              margin: "0px 0px 0px 0px",
                              color: "#000",
                            }}
                          >
                            {slot}
                          </p>
                          <span
                            className={
                              dateSlot?.available_slots > 0
                                ? "green-text"
                                : "red-text"
                            }
                          >
                            {dateSlot?.available_slots > 0
                              ? dateSlot?.available_slots + " Available Slots"
                              : "Sold Out"}
                          </span>
                        </label>
                      );
                    })
                    : null}
                </div>
              </div>
              {homepageSlotError !== "" ? (
                <div className="row" style={{ padding: "0px 16px" }}>
                  <div
                    className="alert alert-info mt-2 error-bar"
                    role="alert"
                    style={{
                      color: "#FC3400",
                      backgroundColor: "#FADEDD",
                      borderColor: "#FADEDD",
                      fontWeight: "700",
                    }}
                  >
                    {homepageSlotError}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="row feature-job-data" style={{ border: "none" }}>
            <div className="col">

              <div className="row" style={{ marginBottom: "0px" }} >
                <div className="col check-div">
                  <input
                    type="checkbox"
                    className='form-check-input'
                    name="isSearchpageSlots"
                    id='isSearchpageSlots'
                    defaultChecked={isSearchpageSlots}
                    onChange={() => setIsSearchpageSlots(!isSearchpageSlots)}
                  />
                  <label className='form-check-label' for='isSearchpageSlots'>Feature on Search Result - {data?.searchpage_feature_gig_slot_price} TT Cash / Slot</label>

                </div>

              </div>
              <div className="row" style={{ marginBottom: "0px" }}>
                <div className="col">

                </div>
              </div>
              <div className='row mt-3'>
                <div className="col">
                  <label className='required' htmlFor="">Enter Number of Slots</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">

                    </div>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Number of slots required"
                      name="searchpageSlots"
                      min={0}
                      max={9}
                      onKeyDown={e => preventPlusMinusInNumberInput(e)}
                      value={searchpageSlots}
                      onChange={(e) => e.target.value <= 9 && setSearchpageSlots(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="times_wrapper">
                  {searchpageJobDateSlots && searchpageJobDateSlots.length > 0 ? searchpageJobDateSlots.map((dateSlot, k) => {
                    var slot = dateSlot?.start_date + '-' + dateSlot?.end_date;
                    return (
                      <label key={"morning" + k}
                        className={`time_label hand-hover position-relative ${searchpageSlotTime.includes(slot) ? 'active-slot' : ''}`}
                        style={{ flexDirection: "column", display: "flex", alignItems: "center" }}
                        onClick={() => toggleSearchSlotSelection(slot)}>
                        <input
                          type="checkbox"
                          style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, appearance: "none" }}
                          name={"slot" + k}
                          checked={searchpageSlotTime.includes(slot)}
                        />
                        <p style={{ margin: "0px 0px 0px 0px", color: "#000" }}>{slot}</p>
                        <span className={dateSlot?.available_slots > 0 ? 'green-text' : 'red-text'}>{dateSlot?.available_slots > 0 ? dateSlot?.available_slots + " Available Slots" : 'Sold Out'}</span>
                      </label>
                    )
                  })
                    :

                    null
                  }
                </div>
              </div>

              {searchpageSlotError !== '' ?
                <div className='row' style={{ padding: "0px 16px" }}>
                  <div className="alert alert-info mt-2 error-bar" role="alert" style={{ color: "#FC3400", backgroundColor: "#FADEDD", borderColor: "#FADEDD", fontWeight: "700" }}  >
                    {searchpageSlotError}
                  </div>
                </div>
                : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-6 mb-2"
            style={{
              display: "flex",
              marginLeft: "14px",
              alignItems: "center",
            }}
          >
            <div
              className="alert-info due-amount-bar"
              role="alert"
              style={{ padding: "6px", float: "right" }}
            >
              Total Cost: {totalAmount} TT Cash
            </div>
            <a
              href={'?tab=' + queryParams.get('tab') + '&action=buy'}
              style={{
                fontSize: "0.94em",
                fontWeight: "500",
                color: "var(--green)",
                textDecoration: "underline",
                marginLeft: "10px",
              }}
            >
              Buy/Allocate
            </a>
          </div>
        </div>
      </AppModal>
    </>
  );
}

export default ModalBuyFeaturedGigSlots;