import { FaMapPin, FaInfoCircle, FaRegFileAlt, FaLongArrowAltRight } from 'react-icons/fa';
import { GoUpload, GoPlus } from "react-icons/go";
import { RiDeleteBinLine, RiCheckDoubleFill } from "react-icons/ri";
import { CiCalendar } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";
import { PiCurrencyInrBold } from "react-icons/pi";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import arrowLeft from '@/Assets/svgs/arrow-left.svg';
import { IoMdThumbsUp, IoMdThumbsDown } from "react-icons/io";
import { MdOutlineReport } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FcGoogle } from "react-icons/fc";
import { FiPlusCircle } from "react-icons/fi";
import { FaBookmark } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { FaClone } from "react-icons/fa";



const IconResource = ({ type, ...props }) => {
  switch (type) {
    case 'mapPin':
      return <FaMapPin {...props} />;
    case 'info':
      return <FaInfoCircle {...props} />;
    case 'upload':
      return <GoUpload {...props} />;
    case 'delete':
      return <RiDeleteBinLine {...props} />;
    case 'file':
      return <FaRegFileAlt {...props} />;
    case 'calendar':
      return <CiCalendar {...props} />;
    case 'search':
      return <IoIosSearch {...props} />;
    case 'rupee':
      return <PiCurrencyInrBold {...props} />;
    case 'eye':
      return <FaRegEye {...props} />;
    case 'eyeClose':
      return <FaRegEyeSlash {...props} />;
    case 'thumbUp':
      return <IoMdThumbsUp {...props} />;
    case 'thumbDown':
      return <IoMdThumbsDown {...props} />;
    case 'doubletick':
      return <RiCheckDoubleFill {...props} />;
    case 'report':
      return <MdOutlineReport {...props} />;
    case 'plus':
      return <GoPlus {...props} />;
    case 'cross':
      return <RxCross2 {...props} />;
    case 'google':
      return <FcGoogle {...props} />;
    case 'rightArrow':
      return <FaLongArrowAltRight {...props} />;
    case 'roundPlus':
      return <FiPlusCircle {...props} />;
    case 'backArrow':
      return <img src={arrowLeft} alt='backArrow' />
    case 'bookmark':
      return <FaBookmark {...props} size={20} />
    case 'heart':
      return <FaHeart {...props} />
    case 'similar':
      return <FaClone {...props} />
    default:
      return null;
  }
};

export default IconResource;
