import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function WorkProfileEditor({ index, workprofileData, setWorkprofileData, element, maxHeight }) {
  const [editorHeight, setEditorHeight] = useState('200px'); // Set the initial height

  const handleChange = (value) => {
    var tempArray = [...workprofileData];
    if (index !== undefined) {
      tempArray[index]['description'] = value;
      setWorkprofileData(tempArray);
    }
  };

  const handleResize = () => {
    const editor = document.querySelector('.ql-editor');
    if (editor) {
      const currentHeight = editor.clientHeight;
      if (currentHeight > maxHeight) {
        setEditorHeight(`${maxHeight}px`);
      } else {
        setEditorHeight('auto');
      }
    }
  };

  return (
    <div style={{ maxHeight: editorHeight, overflow: 'hidden' }}>
      <ReactQuill
        theme="snow"
        value={(element && element.description) ? element.description : ''}
        onChange={handleChange}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean'],
            // [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          ],
        }}
        formats={[
          'bold', 'italic', 'underline', 'strike',
          'list', 'bullet',
          // 'align'
        ]}
        style={{ maxHeight: editorHeight }}
        onKeyUp={handleResize}
        onBlur={handleResize}
      />
    </div>
  );
}

export default WorkProfileEditor;
