import React, { useEffect, useState } from 'react'
import './AddEditCompany.scss';
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import * as toast from "@/wrapper/toast";
import * as storageConstants from '@/constants/storageConstants';
import * as ApiHelper from '@/services/apiHelper';
import * as commonServices from '@/services/common';
import * as errors from '@/constants/stringConstants';
import 'react-phone-number-input/style.css';
import { getUserCompanyId } from '@/utils/authUtil';
import { checkUrl } from '@/utils/validation';
import { getDomainFromUrl, isNullOrEmpty } from '@/utils/stringUtils';
import { ClearFileValues } from '@/utils/fileUtils';
import AddEditCompanyLayout from './AddEditCompanyLayout';
import IconResource from '../_widgets/IconResource/IconResource';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
    position: fixed;
`;


function AddEditCompany({ setTrigger }) {
    const [profile, setProfile] = useState([]);
    const masterData = commonServices.getLocalData(storageConstants.DATA);
    const [showModalVideo, setShowModalVideo] = useState(false);
    const [showModalPreview, setShowModalPreview] = useState(false);


    const navigate = useNavigate();

    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");
    let [logoLoading, setLogoLoading] = useState(false);
    let [coverPhotoLoading, setCoverPhotoLoading] = useState(false);
    let [dataAutoSave, setDataAutoSave] = useState(false);

    var url_string = window.location.href;
    var url = new URL(url_string);

    const setPuffLoading = (type, status) => {
        if (type === 'company') {
            setLogoLoading(status);
        } else if (type === 'company_cover_pic') {
            setCoverPhotoLoading(status);
        }
    }

    const uploadMedia = async (e, type) => {
        const formMedia = new FormData();
        formMedia.append('file', e.target.files[0]);

        formMedia.append('type', type);
        setPuffLoading(type, true);
        await ApiHelper.uploadMedia(formMedia).then((response) => {
            ClearFileValues();

            if (response.isSuccess === true) {
                setPuffLoading(type, false);
                if (type === 'company') {
                    setProfile({ ...profile, 'logo': response.data.file_url })
                } else if (type === 'company_cover_pic') {
                    setProfile({ ...profile, 'cover_pic': response.data.file_url })
                } else if (type === 'company_media') {
                    setFileList(response?.data?.data);
                    setProfile({ ...profile, 'medias': response.data.data })
                }
            }
            else {
                setPuffLoading(type, false);
                toast.warn(response.message);
            }
        });

    }

    const onChange = (e, type = "") => {
        setProfile({
            ...profile,
            [e.target.name]: (e.target.name === 'size_id' || e.target.name === 'industry_domain_id') ? parseInt(e.target.value) : e.target.value,
        });

    }

    useEffect(() => {
        getLatestProfile();
    }, [])

    useEffect(() => {
        if (dataAutoSave) {
            updateCompanyProfile();
        }
    }, [dataAutoSave])

    const getLatestProfile = async () => {
        setLoading(true);
        await ApiHelper.getCompanyDetails(getUserCompanyId()).then((response) => {
            setLoading(false);
            if (response.isSuccess === true) {
                setProfile(response?.data);
                setFileList(response?.data?.medias);
                var profile = commonServices.getLocalData(storageConstants.PROFILE);
                profile.company.progress = response?.data?.progress;
                commonServices.storeLocalData(storageConstants.PROFILE, profile);
            } else {
                toast.warn(response.message);
            }
        });
    }

    const validateData = (data) => {
        let status = true;
        if (profile.name === "") {
            status = false;
            toast.warn(errors.COMPANY_NAME_REQUIRED);
        }
        // else if (profile.name.length > 30) {
        //     status = false;
        //     toast.warn(errors.COMPANY_NAME_LIMIT_ERROR);
        // }

        else if (isNullOrEmpty(profile.address)) {
            status = false;
            toast.warn(errors.COMPANY_ADDRESS_REQUIRED);
        }
        else if (!isNullOrEmpty(profile.instagram) && !checkUrl(profile.instagram)) {
            status = false;
            toast.warn(errors.VALID_INSTAGRAM_LINK);
        }
        else if (!isNullOrEmpty(profile.linkedin) && !checkUrl(profile.linkedin)) {
            status = false;
            toast.warn(errors.VALID_LINKEDIN_LINK);
        }
        else if (!isNullOrEmpty(profile.twitter) && !checkUrl(profile.twitter)) {
            status = false;
            toast.warn(errors.VALID_TWITTER_LINK);
        }
        else if (!isNullOrEmpty(profile.facebook) && !checkUrl(profile.facebook)) {
            status = false;
            toast.warn(errors.VALID_FACEBOOK_LINK);
        }

        return status;
    }
    const updateCompanyProfile = async (action = 'preview') => {

        if (validateData(profile)) {
            if (action === 'preview') {
                setShowModalPreview(true);
                return;
            } else {
                setShowModalPreview(false);
                delete profile.cover_pic;
                delete profile.logo;
                delete profile.name;
                setLoading(true);
                await ApiHelper.addEditCompany(profile).then((response) => {
                    setLoading(false);
                    setDataAutoSave(false);
                    if (response.isSuccess === true) {
                        getLatestProfile();
                        ClearFileValues();
                        toast.success(response.message);
                    }
                    else {
                        toast.warn(response.message);
                    }
                });
            }

        }
    }

    const deleteCoverPic = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('id', profile.id);
        formData.append('cover_pic', '');
        await ApiHelper.addEditCompany(formData).then((response) => {
            setLoading(false);
            setDataAutoSave(false);
            if (response.isSuccess === true) {
                getLatestProfile();
                toast.success(response.message);
            }
            else {
                toast.warn(response.message);
            }
        });
    }




    //////////////////////////// Media upload

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
    };

    const handleRemove = (index) => {
        var removeId = fileList[index]?.uid;
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
        setLoading(true);
        const formData = new FormData();
        formData.append('removeId', removeId);
        ApiHelper.deleteCompanyMedia(formData).then((response) => {
            setLoading(false);
            setFileList(response?.data);
            setProfile({ ...profile, 'medias': response?.data })
        });
    };

    const deleteLink = async (e, data) => {
        e.preventDefault();
        var tempProfileArr = { ...profile };
        tempProfileArr.links.splice(data, 1);
        setProfile(tempProfileArr);
    }
    const uploadButton = (
        <div>
            <IconResource type="roundPlus" size={20} />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const validDomain = (domain) => {
        if (profile?.domains !== undefined && profile?.domains.length >= 5) {
            toast.warn(errors.MAXIMUM_ADDITIONAL_DOMAINS_ERROR_MESSAGE);
            return false;
        }
        if (!checkUrl(domain)) {
            toast.warn(errors.INVALID_ADDITIONAL_DOMAIN_ERROR_MESSAGE);
            return false;
        }

        let domains = profile.domains.map(domain => domain?.name).toString().split(',');

        if (domains.includes(domain)) {
            toast.warn(errors.ADDITIONAL_DOMAIN_EXISTS_ERROR_MESSAGE);
            return false;
        }

        return true;
    }

    const addDomain = (e) => {
        if (e.which === 13 || e.keyCode === 13) {
            let urlDomain = getDomainFromUrl(e.target.value);
            if (validDomain(urlDomain)) {
                let tempProfile = { ...profile };
                if (tempProfile?.domains === undefined)
                    tempProfile.domains = [];
                let domain = {
                    "name": urlDomain,
                    "verified": "0",
                };
                tempProfile.domains.push(domain);
                setProfile(tempProfile);
                e.target.value = '';
            }
        }
    }

    const deleteDomain = (e, key) => {
        e.preventDefault();
        var tempProfileArr = { ...profile };
        tempProfileArr.domains.splice(key, 1);
        setProfile(tempProfileArr);
    }

    return (
        <AddEditCompanyLayout
            loading={loading}
            profile={profile}
            showModalVideo={showModalVideo}
            setShowModalVideo={setShowModalVideo}
            setProfile={setProfile}
            setDataAutoSave={setDataAutoSave}
            showModalPreview={showModalPreview}
            setShowModalPreview={setShowModalPreview}
            updateCompanyProfile={updateCompanyProfile}
            masterData={masterData}
            coverPhotoLoading={coverPhotoLoading}
            uploadMedia={uploadMedia}
            color={color}
            logoLoading={logoLoading}
            onChange={onChange}
            addDomain={addDomain}
            deleteDomain={deleteDomain}
            uploadButton={uploadButton}
            deleteLink={deleteLink}
            handleRemove={handleRemove}
            handleChange={handleChange}
            handlePreview={handlePreview}
            handleCancel={handleCancel}
            previewTitle={previewTitle}
            previewImage={previewImage}
            previewOpen={previewOpen}
            deleteCoverPic={deleteCoverPic}
            fileList={fileList}
        />
    )
}

export default AddEditCompany