// response.js
import * as errors from '../constants/stringConstants';
import * as commonServices from '../services/common';
import { SUCCESS_CODE, UNAUTHORIZED_ERROR_CODE, NOT_FOUND_CODE } from '../constants/responseCodes';

import * as analytics from '@/helpers/analytics';
import { analyticsConstant } from '../constants/analyticsConstant';
import { consoleLogE } from '@/utils/console';

export function handleResponse(response) {
    if (response.data.code === SUCCESS_CODE) {
        response.data.isSuccess = true;
    } else if (response.data.code === UNAUTHORIZED_ERROR_CODE) {
        commonServices.clearLocalStorageData();
        window.location.href = '/?auth=login';
        return;
    } else {
        response.data.isSuccess = false;
    }

    // console.log(response.config.url, response.data.code, response.data.message)

    analytics.trackEvent(analyticsConstant.SYSTEM_UP_TIME, {
        code: response.data.code,
        url: response.config.url
    })

    return response.data;
}

export function handleError(error) {



    var { response } = error;
    try {
        analytics.trackEvent(analyticsConstant.SYSTEM_UP_TIME, {
            code: response.data.code,
            url: response.config.url
        })
    } catch (err) {
        consoleLogE(err);
    }


    if (typeof response === 'undefined') {
        response = [];
    }
    if (typeof response.data === 'undefined' || response.data === null || response.data === '') {
        response.data = [];
    }
    if(response?.data?.data && typeof response?.data?.data === 'object' && Object.keys(response?.data?.data).length === 0){
        response.data.data = [];
    }
    response.data.isSuccess = false;
    if (typeof response.data.message === 'undefined' || response.data.message === null || response.data.message === '') {
        response.data.message = errors.SOMETHING_WENT_WRONG;
    }
    if (response.status === UNAUTHORIZED_ERROR_CODE) {
        commonServices.clearLocalStorageData();
        window.location.href = '/?auth=login';
        return;
    } else if (response.status === NOT_FOUND_CODE) {
        window.location.href = '/not-found';
        return;
    } else {
        return response.data;
    }

}