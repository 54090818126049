import React, { useState, useEffect } from 'react'
import './Login.scss'
import { useNavigate, Navigate } from "react-router-dom";
import * as toast from "@/wrapper/toast";

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as erros from '@/constants/stringConstants';
import * as storageConstants from '@/constants/storageConstants';
import * as ApiHelper from '@/services/apiHelper';
import * as commonServices from '@/services/common';
import { APP_NAME } from '@/constants/storageConstants';
import { updateHeaderMessageIcon } from '@/utils/messageUtil';
import { READ, UNREAD } from '@/constants/messageConstants';
import { isLoggedIn } from '@/utils/authUtil';
import ModalContactDetails from "../Modals/ModalContactDetails";
import { BUTTON_TEXT_OK } from "@/constants/stringConstants";
import googleLogo from "@/Assets/images/google-logo.png";
import ModalRestoreAccount from "../Modals/ModalRestoreAccount";
import RegistrationSteps from '../Register/RegistrationSteps/RegistrationSteps';
import { MaxWidthContainer } from '../Common/MaxWidthContainer';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function Login({ isHeaderUpdate, setIsHeaderUpdate }) {

  var url_string = window.location.href;
  var url = new URL(url_string);
  var isVerified = url.searchParams.get("verify");
  var msg = url.searchParams.get("msg");
  var verifyUrl = url.searchParams.get("url");
  var verifyUrlHash = url.searchParams.get("hash");
  var verifyUrlSignature = url.searchParams.get("signature");
  var redirect = url.searchParams.get("redirect");

  const [passwordShown, setPasswordShown] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showModalError, setShowModalError] = useState(false);
  const [showModalRestore, setShowModalRestore] = useState(false);
  const [showModalApplyJob, setShowModalApplyJob] = useState(false);
  const [showStep, setShowStep] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [modalErrorData, setModalErrorData] = useState();
  const [profile, setProfile] = useState([]);
  const [referredData, setReferredData] = useState([]);


  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");


  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);


  const navigate = useNavigate();
  const changeRegister = () => {
    navigate("/register");
  }

  const changeForget = () => {
    navigate("/forgot-password");
  }

  const getGoogleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    ApiHelper.googleLogin().then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        if (response.data !== null) {
          let googleUrl = response.data.url;
          var a = document.createElement('a');
          a.href = googleUrl;
          a.click();
        }
      }
      else {
        toast.warn(response.message);
      }
    });
  }

  const changeHome = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.warn(erros.EMAIL_REQUIRED)
    } else if (!commonServices.checkEmailValid(email)) {
      toast.warn(erros.INCORRECT_EMAIL);
    } else if (!password) {
      toast.warn(erros.PASSWORD_REQUIRED)
    } else if (email && password) {
      setLoading(true);
      await ApiHelper.login(formData).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          if (response.data !== null) {
            if (response.data.is_profile_completed && response.data.is_mobile_verified) {
              commonServices.storeLocalData(storageConstants.AUTH, response.data.token);
              commonServices.storeLocalData(storageConstants.USER_ROLE, response.data.role_type);
              commonServices.storeLocalData(storageConstants.PROFILE, response.data.profile);
              setIsHeaderUpdate(!isHeaderUpdate);
              if (response?.data?.user_unread_messages > 0) {
                updateHeaderMessageIcon(UNREAD);
              } else {
                updateHeaderMessageIcon(READ);
              }
            }
            setProfile(response.data.profile);
            if (!response.data.is_profile_completed) {
              setCurrentStep(1);
              setShowStep(true);


              return;
            } else if (!response.data.is_mobile_verified) {
              setCurrentStep(3);
              setShowStep(true);
              return;
            } else if (response.data.token && (response.data.role_type === 'CD' || response.data.role_type === 'EV') && response.data.profile !== null && response.data.profile.progress < 100) {

              setShowModalApplyJob(response?.data?.profile?.show_apply_popup);
              setReferredData(response?.data?.profile?.referral_data);


              if (response.data.profile.user_work_profile !== null && response.data.profile.user_work_profile.skip_count > 0) {

                redirect = '/workprofile?profileInProgress=1';
              } else {
                setCurrentStep(4);
                setShowStep(true);

                return;

              }





            } else if (response.data.token && response.data.role_type === 'EV' && response.data.profile !== null && response.data.profile.progress >= 100) {
              redirect = '/evaluator/dashboard';
            }

            if (redirect !== null) {
              navigate(redirect);
            } else {
              navigate('/');
            }
          }
        }
        else {
          console.log("response->", response);
          if (response.code === 203) {
            setShowModalRestore(!showModalRestore);
          } else if (response.code === 201) {
            setShowModalError(true);
            setModalErrorData({
              ...modalErrorData,
              'heading': "Account Deleted",
              'paragraph': response.message,
              'buttonText': BUTTON_TEXT_OK,
            });
          } else {
            toast.warn(response.message);
            if (response.data !== undefined && (response.data.length > 0 || response.data.length === undefined)) {
              if (response.data.show_email_screen) {
                setProfile(response.data.user);
                setCurrentStep(3);
                setShowStep(true);
              } else if (!response.data.is_profile_completed) {
                setProfile(response.data.profile);
                setCurrentStep(1);
                setShowStep(true);
                return;
              } else if (!response.data.is_mobile_verified) {
                setProfile(response.data.profile);
                setCurrentStep(3);
                setShowStep(true);
                return;
              }
            }
          }

        }
      });

    } else {
      toast.warn(erros.EMAIL_PASSWORD_REQUIRED);
    }

  }

  const restoreAccount = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.warn(erros.EMAIL_REQUIRED)
    } else if (!password) {
      toast.warn(erros.PASSWORD_REQUIRED)
    } else if (email && password) {
      setLoading(true);
      setShowModalRestore(!showModalRestore);
      await ApiHelper.restoreAccount(formData).then((response) => {
        setLoading(false);

        if (response.isSuccess === true) {
          toast.success(response.message);
        }
        else {
          toast.warn(response.message);

        }
      });

    } else {
      toast.warn(erros.EMAIL_PASSWORD_REQUIRED);
    }

  }

  useEffect(() => {

    setShowStep(false);
    if (isLoggedIn()) {
      navigate('/')
    }
    var options = { hideAfter: 5 };
    if (isVerified !== null) {
      commonServices.clearLocalStorageData();
      toast.success('Email verified successfully!', options);
      navigate('/login');
    }

    if (msg !== null) {
      setShowModalError(true);
      setModalErrorData({
        ...modalErrorData,
        'heading': "Registration Successful, please verify your email",
        'paragraph': msg,
        'buttonText': BUTTON_TEXT_OK,
      });
    }
    if (verifyUrl !== null) {
      var verifyLink = verifyUrl + "&hash=" + verifyUrlHash + "&signature=" + verifyUrlSignature;
      setLoading(true);
      ApiHelper.emailVerification(verifyLink).then((response) => {
        setLoading(false);
        if (response.isSuccess === true) {
          toast.success(response.message);
          navigate('/login');
        }
        else {
          toast.warn(response.message);
          navigate('/login');
        }
      });
    }
  }, [])



  var auth = commonServices.getLocalData(storageConstants.AUTH);

  return (<>{loading ?
    <div className='loader'>
      <ClipLoader color={color} loading={true} css={override} size={150} /> </div> : null}<>

      <ModalContactDetails showModal={showModalError} setShowModal={setShowModalError} modalData={modalErrorData} />
      <ModalRestoreAccount showModal={showModalRestore} setShowModal={setShowModalRestore} restoreAccount={restoreAccount} />
      {showStep ?
        <RegistrationSteps currentStep={currentStep} setCurrentStep={setCurrentStep} profile={profile} setProfile={setProfile} showModalApplyJob={showModalApplyJob} referredData={referredData} isHeaderUpdate={isHeaderUpdate} setIsHeaderUpdate={setIsHeaderUpdate} setShowStep={setShowStep} />
        :
        <MaxWidthContainer>
          <div style={{
            minHeight: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }} className="login_page">
            <div className="main">
              <div className="left">
                <div className="left_content" >
                  <img onError={commonServices.imgError} className="cover_image" src="./Assets/images/login_banner.png?1" alt="" />
                  <h2 className="content">
                    Experience Hiring 2.0 with <span>TrueTalent</span>
                  </h2>
                  <button className="btn content-button" type="button" onClick={() => { navigate("/about"); }} >
                    Learn more about {APP_NAME}
                  </button>
                </div>
              </div>
              <div className="right">
                <div className="right_content">
                  <h2 className="content_heading text-center mt-2 mt-lg-3 mb-3 mb-lg-4">Log in to {APP_NAME}</h2>
                  <p className="register_txt mobile-text text-center">Don't have an account? <a onClick={changeRegister}>Register</a> </p>
                  <div className="ss-b">

                  </div>
                  <form action="">
                    <div className="input_section">
                      <div className="input_icon"><img onError={commonServices.imgError} src="./Assets/images/mail.png?1" alt="" /></div>

                      <div className=" form-floating">
                        <input type="email" className="form-control" id="LoginEmail" placeholder="name@example.com" onChange={(e) => { setEmail(e.target.value) }} />
                        <label htmlFor="LoginPassword">Email</label>
                      </div>
                    </div>

                    <div className="input_section">
                      <div className="input_icon"><img onError={commonServices.imgError} src="./Assets/images/lock.png?1" alt="" /></div>

                      <div className=" form-floating">
                        <input type={passwordShown ? "text" : "password"} className="form-control" id="LoginPassword" placeholder="password" onChange={(e) => { setPassword(e.target.value) }} />
                        <label htmlFor="LoginPassword">Password</label>
                      </div>
                      {passwordShown ? <div className="input_icon" onClick={() => setPasswordShown(!passwordShown)}><img onError={commonServices.imgError} src="./Assets/images/eye_closed.png?1" alt="" /></div> :
                        <div className="input_icon" onClick={() => setPasswordShown(!passwordShown)}><img onError={commonServices.imgError} src="./Assets/images/eye.png?1" alt="" /></div>
                      }
                    </div>

                    <div className="row rem_div">
                      <div className="col">
                        <label className="check_container form-check-label" htmlFor="login_1">Remember Me
                          <input type="checkbox" id='login_1' />
                          <span className="checkmark"></span>
                        </label>
                      </div>

                      <div className="col text-end">
                        <a className="body-links " onClick={changeForget}>Forgot Password?</a>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col mb-2 mb-md-2 mb-lg-4 text-center">
                        <button className="btn form_button mb-3" onClick={changeHome} >Sign In</button>
                        {/* <div class="or_line">OR</div>
                        <button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className='btn form_button social-btn my-3' onClick={(e) => getGoogleLogin(e)} > <img src={googleLogo} alt='google' /> Continue With Google </button>
                        <span >(for Job Seekers only)</span> */}
                      </div>
                    </div>
                  </form>
                  <div>
                  </div>
                </div>
              </div>
              {/* <div className='social'>
            <div className="row">
              <div className="col text-center">
                <p className="register_txt">Don't have an account? <a onClick={changeRegister}>Register</a> </p>
              </div>

            </div>
          </div> */}
            </div>
          </div>
        </MaxWidthContainer>
      }
    </> </>
  )
}

export default Login