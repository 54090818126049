export const BASE_URL = "https://ttadmin.appringer.co.in/api/";
export const MASTER_DATA = "master_data";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const CANDIDATE_REGISTER = "candidate_register";
export const DASHBOARD = "company/dashboard";
export const STATIC_DASHBOARD = "company/static/dashboard";
export const COMPANY_REGISTER = "company_register";
export const CHANGE_PASSWORD = "change_password";
export const UPDATE_PROFILE = "update_profile";
export const BLOCK_UNBLOCK_COMPANY = "block_unblock_company";
export const GET_COMPANY_LIST = "get_company_list";
export const UPLOAD_MEDIA = "upload_media";
export const RESUME_PARSER = "resume-parser";
export const FORGOT_PASSWORD_SEND_OTP = "forgot_password_send_otp";
export const RESET_PASSWORD = "forgot_password";
export const MY_JOBS = "get_my_jobs";
export const JOB_DETAILS = "job_details?job_id=";
export const APPLY_SAVE_JOB = "apply_job";
export const REPORT_JOB = "report_job";
export const CLOSE_JOB = "close_job";
export const UPDATE_WORKPROFILE = "update_work_profile";
export const SEARCH_JOB = "search_job";
export const SEARCH_CANDIDATE = "search_candidate";
export const SEARCH_CANDIDATE_GRAPH_DATA = "search/candidates/graphs";
export const ADD_SKILL = "add_skill";
export const ADD_EDIT_JOB = "add_edit_job";
export const COMPANY_USERS = "company_users";
export const ADD_EDIT_COMPANY_USER = "add_edit_company_user";
export const UPDATE_COMPANY_USER_STATUS = "update_company_user_status";
export const UPDATE_COMPANY_USER_ROLE = "update_company_user_role";
export const OFFLINE_PAYMENT = "offline_payment";
export const ONLINE_PAYMENT = "online_payment";
export const ADD_AND_BLOCK_COMPANY = "add_and_block_company";
export const USER_WORK_PROFILE = "user_work_profile";
export const CHANGE_APPLICANT_STATUS = "change_applicant_status";
export const CONTACT = "contact";
export const FEATURED_JOBS = "featured_jobs?page=";
export const FEATURED_GIGS = "featured_gigs?page=";
export const HOMEPAGE_LOGOS = "homepage_logos";
export const USER_PROFILE = "profile";
export const BUY_EVALUATION = "buy_evaluation/";
export const LOCATIONS = "locations?type=";
export const FETCH_LOCATION = "fetch_location?q=";
export const CHANGE_JOB_STATUS = "change_job_status";
export const DOWNLOAD_WORKPROFILE = "download-workprofile?id=";
export const FETCH_SKILL = "fetch_skill?q=";
export const SKILLS = "skills";
export const ADD_EDIT_COMPANY = "add_edit_company";
export const DELETE_COMPANY_MEDIA = "delete_company_media";
export const GOOGLE_PLACES = "google_places?q=";
export const GET_COMPANY_DETAILS = "get_company_details/";

export const CREATE_CHAT = "chat/message/send";
export const GET_CHAT = "chat/get";
export const GET_CHAT_MESSAGES = "chat/messages";

export const UPDATE_CHAT = "chat/update";
export const DELETE_CHAT = "chat/delete";

export const MY_GIGS = "gig/my_gigs?order_by=";
export const GIG_DETAILS = "gig/details/";
export const ADD_EDIT_GIG = "gig/add_edit";
export const UPDATE_STATUS_GIG = "gig/status/update";
export const CLOSE_GIG = "gig/close";
export const APPLY_GIG = "gig/apply";
export const REPORT_GIG = "gig/report";
export const GIG_LIST = "gigs/list";
export const GIG_APPLICANT_UPDATE = "gig/applicant/update";
export const RENEW_GIG = "gig/renew";
export const GIG_FEATURE = 'gig/feature/add';
export const GET_DESCRIPTION_BY_CHATGPT = "get_description";
export const RENEW_JOB = "renew_job";

export const ADD_PROFILE_TRACKING = 'tracking/add';
export const PLACE_ORDER = 'place_order';
export const VERIFY_PAYMENT = 'verify_payment';
export const VERIFY_RAZORPAY_PAYMENT = 'verify-razorpay-payment';
export const GET_TRANSACTIONS = 'transactions';
export const EXPORT_TRANSACTIONS = 'export_transactions';
export const COMPANY_REPORTING = 'company_reporting';
export const GIVEAWAY_WINNERS = 'giveaway_winners';
export const GOOGLE_LOGIN = 'google-login';
export const GOOGLE_LOGIN_V2 = 'auth/google-login';
export const RESEND_VERIFICATION_EMAIL = 'resend_verification_email';
export const REFERRALS_LIST = 'referrals/list';
export const REFERRALS_USERS = 'referrals/users';
export const REFERRALS_INVITATION_SEND = 'referrals/invitation/send';
export const DUPLICATE_JOB = 'duplicate_job';
export const DUPLICATE_GIG = 'gig/duplicate';
export const RECRUITER_DETAILS = 'company/recruiter/';
export const DELETE_ACCOUNT = 'account/delete';
export const RESTORE_ACCOUNT = 'account/restore';
export const JOB_BOOST = 'job/boost';
export const JOB_FEATURE = 'job/feature/add';
export const JOB_REFERRAL_END = 'job/referral/end/';
export const OTP_VERIFY = 'otp/verify';
export const EMAIL_OTP_VERIFY = 'email/otp/verify';
export const SKIP_PROFILE = 'skip_profile';
export const SMART_APPLY = 'smart_apply';

export const GET_SAVE_CANDIDATES = 'save-candidate';
export const SAVE_CANDIDATE = 'save-candidate/add';
export const DELETE_SAVE_CANDIDATE = 'save-candidate/delete';

export const GET_SAVE_CRITERIAS = "save-search"
export const SAVE_CRITERIA = "save-search/add";
export const DELETE_SAVE_CRITERIA = 'save-search/delete';




// EVALUATOR
export const GET_INTERVIEW_DETAILS = 'interview/';
export const GET_USER_INTERVIEWS = 'interviews';
export const EVALUATOR_UPDATE_SLOT = 'evaluator/update-slots';
export const EVALUATOR_GET_SLOT = 'evaluator/slots';
export const EVALUATOR_CALENDAR = 'evaluator/calendar';
export const EVALUATOR_DASHBOARD = 'evaluator/dashboard';

//EVALUATION
export const CHECK_CANDIDATE_PRO_STATUS = 'evaluation/pro_invite';
export const SEND_CANDIDATE_PRO_REQUEST = 'evaluation/pro/request';
export const BOOK_APPOINTMENT = 'evaluation/appointment';
export const EVALUATION_SLOTS = 'evaluation/slots';
export const CHECK_EVALUATION_APPOINTMENT = 'evaluation/check/appointment';
export const UPDATE_APPOINTMENT_STATUS = 'evaluation/appointment/update/status';
export const EVALUATION_FEEDBACK = 'evaluation/feedback';
export const PARTNER_CANDIDATES = 'partner_candidates';
export const PARTNER_CANDIDATES_APPLIED_JOBS = 'partner_candidates_applied_jobs';
export const PARTNER_CANDIDATES_APPLIED_GIGS = 'partner_candidates_applied_gigs';
export const PARTNER_DASHBOARD = 'partner_dashboard';

//RESUME
export const RESUME_DATA = 'resume_data';
export const RESUME_BY_AI = 'resume/ai';

// Featured Job Slots Management
export const FEATURED_JOB_SLOTS = 'featured_job_slots';
export const FEATURED_JOB_BUY_SLOTS = 'featured_job_slots/buy-slots';
export const FEATURED_JOB_CHECK_SLOTS = 'featured_job_slots/check-slots';
export const UPDATE_JOB_SLOT = 'featured_job_slots/update-slot-job';
export const ADD_JOB_IMPRESSION = 'featured_job_slots/add-job-impression';
export const GET_JOB_ACTIVITY = 'featured_job_slots/activity/';
export const GET_JOB_SLOTS_HISTORY = 'featured_job_slots/history';

// Featured Gig Slots Management
export const FEATURED_GIG_SLOTS = 'featured_gig_slots';
export const FEATURED_GIG_BUY_SLOTS = 'featured_gig_slots/buy-slots';
export const FEATURED_GIG_CHECK_SLOTS = 'featured_gig_slots/check-slots';
export const UPDATE_GIG_SLOT = 'featured_gig_slots/update-slot-gig';
export const ADD_GIG_IMPRESSION = 'featured_gig_slots/add-gig-impression';
export const GET_GIG_ACTIVITY = 'featured_gig_slots/activity/';
export const GET_GIG_SLOTS_HISTORY = 'featured_gig_slots/history';

// Reports
export const DOWNLOAD_REPORTS = 'report/download';
export const SEND_RESUME_OTP = 'resume/send_otp';
export const VERIFY_RESUME_OTP = 'resume/verify_otp';
export const RESUME_CANDIDATE_REGISTER = 'resume/candidate_register';
export const RESUME_VERIFY_PAYMENT = 'resume/verify_payment';

// Company Reports
export const DOWNLOAD_COMPANY_REPORT = 'company/report';