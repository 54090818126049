import * as apiProvider from "./provider";
import * as urlConstants from "../constants/urlConstants";

export function getMasterData() {
  return apiProvider.getAll(urlConstants.MASTER_DATA);
}

export function login(reqData) {
  return apiProvider.post(urlConstants.LOGIN, reqData);

}

export function logout(reqData) {
  return apiProvider.post(urlConstants.LOGOUT, reqData);
}

export function candidateRegister(reqData) {
  return apiProvider.post(urlConstants.CANDIDATE_REGISTER, reqData);
}

export function companyRegister(reqData) {
  return apiProvider.post(urlConstants.COMPANY_REGISTER, reqData);
}

export function changePassword(reqData) {
  return apiProvider.post(urlConstants.CHANGE_PASSWORD, reqData);
}

export function forgotPasswordSendOtp(reqData) {
  return apiProvider.post(urlConstants.FORGOT_PASSWORD_SEND_OTP, reqData);
}

export function resetPassword(reqData) {
  return apiProvider.post(urlConstants.RESET_PASSWORD, reqData);
}

export function updateProfile(reqData) {
  return apiProvider.post(urlConstants.UPDATE_PROFILE, reqData);
}

export function blockUnblockCompany(reqData) {
  return apiProvider.post(urlConstants.BLOCK_UNBLOCK_COMPANY, reqData);
}

export function getCompanyList() {
  return apiProvider.getAll(urlConstants.GET_COMPANY_LIST);
}

export function uploadMedia(reqData) {
  return apiProvider.post(urlConstants.UPLOAD_MEDIA, reqData);
}

export function parseResume(reqData) {
  return apiProvider.post(urlConstants.RESUME_PARSER, reqData);
}

export function getMyJobs(params) {
  return apiProvider.getAll(urlConstants.MY_JOBS, params);
}

export function getJobDetail(job_id) {
  return apiProvider.get(urlConstants.JOB_DETAILS, job_id);
}

export function applySaveJob(reqData) {
  return apiProvider.post(urlConstants.APPLY_SAVE_JOB, reqData);
}

export function reportJob(reqData) {
  return apiProvider.post(urlConstants.REPORT_JOB, reqData);
}

export function closeJob(reqData) {
  return apiProvider.post(urlConstants.CLOSE_JOB, reqData);
}

export function updateWorkProfile(reqData) {
  return apiProvider.post(urlConstants.UPDATE_WORKPROFILE, reqData);
}

export function searchJob(reqData) {
  return apiProvider.post(urlConstants.SEARCH_JOB, reqData);
}

export function searchCandidate(reqData) {
  return apiProvider.post(urlConstants.SEARCH_CANDIDATE, reqData);
}

export function searchCandidateGraphData(reqData) {
  return apiProvider.post(urlConstants.SEARCH_CANDIDATE_GRAPH_DATA, reqData);
}

export function addSkill(reqData) {
  return apiProvider.post(urlConstants.ADD_SKILL, reqData);
}

export function addEditJob(reqData) {
  return apiProvider.post(urlConstants.ADD_EDIT_JOB, reqData);
}

export function getCompanyUsers(reqData) {
  return apiProvider.getAll(urlConstants.COMPANY_USERS, reqData);
}

export function addEditCompanyUser(reqData) {
  return apiProvider.post(urlConstants.ADD_EDIT_COMPANY_USER, reqData);
}

export function updateCompanyUserStatus(reqData) {
  return apiProvider.post(urlConstants.UPDATE_COMPANY_USER_STATUS, reqData);
}

export function updateCompanyUserRole(reqData) {
  return apiProvider.post(urlConstants.UPDATE_COMPANY_USER_ROLE, reqData);
}

export function offlinePayment(reqData) {
  return apiProvider.post(urlConstants.OFFLINE_PAYMENT, reqData);
}

export function onlinePayment(reqData) {
  return apiProvider.post(urlConstants.ONLINE_PAYMENT, reqData);
}

export function addBlockCompany(reqData) {
  return apiProvider.post(urlConstants.ADD_AND_BLOCK_COMPANY, reqData);
}

export function getUserWorkProfile(user_id) {
  return apiProvider.get(urlConstants.USER_WORK_PROFILE, user_id);
}

export function changeApplicantStatus(reqData) {
  return apiProvider.post(urlConstants.CHANGE_APPLICANT_STATUS, reqData);
}

export function contact(reqData) {
  return apiProvider.post(urlConstants.CONTACT, reqData);
}

export function getFeaturedJobs(q = "homepage") {
  return apiProvider.getAll(urlConstants.FEATURED_JOBS, q);
}

export function getHomepageLogos() {
  return apiProvider.getAll(urlConstants.HOMEPAGE_LOGOS);
}

export function getProfile() {
  return apiProvider.getAll(urlConstants.USER_PROFILE);
}

export function buyCandidateEvaluation(user_id) {
  return apiProvider.get(urlConstants.BUY_EVALUATION, user_id);
}

export function getLocations(type = "") {
  return apiProvider.getAll(urlConstants.LOCATIONS, type);
}

export function getLocationByNameOrPincode(q) {
  return apiProvider.get(urlConstants.FETCH_LOCATION, q);
}

export function changeJobStatus(reqData) {
  return apiProvider.post(urlConstants.CHANGE_JOB_STATUS, reqData);
}

export function getSkillByName(q) {
  return apiProvider.get(urlConstants.FETCH_SKILL, encodeURIComponent(q));
}

export function getSkills() {
  return apiProvider.getAll(urlConstants.SKILLS);
}

export function companyStaticDashboard(q = "") {
  return apiProvider.get(urlConstants.STATIC_DASHBOARD, q);
}

export function companyDashboard(q = "") {
  return apiProvider.get(urlConstants.DASHBOARD, q);
}

export function addEditCompany(reqData) {
  return apiProvider.post(urlConstants.ADD_EDIT_COMPANY, reqData);
}

export function deleteCompanyMedia(reqData) {
  return apiProvider.post(urlConstants.DELETE_COMPANY_MEDIA, reqData);
}

export function getGooglePlaces(q = "") {
  return apiProvider.get(urlConstants.GOOGLE_PLACES, q);
}

export function getCompanyDetails(id = "") {
  return apiProvider.get(urlConstants.GET_COMPANY_DETAILS, id);
}

export function createChat(reqData) {
  return apiProvider.post(urlConstants.CREATE_CHAT, reqData);
}

export function getChat(q = "") {
  return apiProvider.get(urlConstants.GET_CHAT, q);
}

export function getChatMessages(id, q = "") {
  return apiProvider.get(urlConstants.GET_CHAT_MESSAGES + `/${id}`, q);
}

export function updateChat(id, reqData) {
  return apiProvider.post(urlConstants.UPDATE_CHAT + `/${id}`, reqData);
}

export function deleteChat(id, reqData) {
  return apiProvider.post(urlConstants.DELETE_CHAT + `/${id}`, reqData);
}

export function getMyGigs(orderBy) {
  return apiProvider.getAll(urlConstants.MY_GIGS, orderBy);
}

export function getGigDetail(gig_id) {
  return apiProvider.get(urlConstants.GIG_DETAILS, gig_id);
}

export function addEditGig(reqData) {
  return apiProvider.post(urlConstants.ADD_EDIT_GIG, reqData);
}

export function updateStatusGig(reqData) {
  return apiProvider.post(urlConstants.UPDATE_STATUS_GIG, reqData);
}

export function closeGig(reqData) {
  return apiProvider.post(urlConstants.CLOSE_GIG, reqData);
}

export function applyGig(reqData) {
  return apiProvider.post(urlConstants.APPLY_GIG, reqData);
}

export function reportGig(reqData) {
  return apiProvider.post(urlConstants.REPORT_GIG, reqData);
}

export function searchGig(reqData) {
  return apiProvider.post(urlConstants.GIG_LIST, reqData);
}
export function getFeaturedGigs(q = 'homepage') {
  return apiProvider.getAll(urlConstants.FEATURED_GIGS, q);
}

export function changeGigApplicantStatus(reqData) {
  return apiProvider.post(urlConstants.GIG_APPLICANT_UPDATE, reqData);
}

export function getDescriptionByChatGPT(reqData) {
  return apiProvider.get(urlConstants.GET_DESCRIPTION_BY_CHATGPT, reqData);
}

export function reNewJob(reqData) {
  return apiProvider.post(urlConstants.RENEW_JOB, reqData);
}
export function addProfileTrackingData(reqData) {
  return apiProvider.post(urlConstants.ADD_PROFILE_TRACKING, reqData);
}

export function placeOrder(reqData) {
  return apiProvider.post(urlConstants.PLACE_ORDER, reqData);
}

export function verifyPayment(reqData) {
  return apiProvider.post(urlConstants.VERIFY_PAYMENT, reqData);
}

export function verifyRazorpayPayment(reqData) {
  return apiProvider.post(urlConstants.VERIFY_RAZORPAY_PAYMENT, reqData);
}

export function getTransactions(queryParams = "") {
  return apiProvider.get(urlConstants.GET_TRANSACTIONS, queryParams);
}

export function exportTransactions(reqData) {
  return apiProvider.post(urlConstants.EXPORT_TRANSACTIONS, reqData);
}

export function getCompanyReporting() {
  return apiProvider.get(urlConstants.COMPANY_REPORTING, "");
}

export function downloadCompanyReport(queryParams) {
  return apiProvider.get(urlConstants.DOWNLOAD_COMPANY_REPORT, queryParams);
}

export function emailVerification(url) {
  return apiProvider.verifyEmail(url);
}

export function getGiveawayWinners() {
  return apiProvider.get(urlConstants.GIVEAWAY_WINNERS, "");
}

export function googleLogin() {
  return apiProvider.get(urlConstants.GOOGLE_LOGIN, "");
}

export function googleLoginV2(reqData) {
  return apiProvider.post(urlConstants.GOOGLE_LOGIN_V2, reqData);
}

export function googleLoginCallback(url) {
  return apiProvider.post(url);
}

export function resendVerificationEmail(reqData) {
  return apiProvider.post(urlConstants.RESEND_VERIFICATION_EMAIL, reqData);
}

export function getReferrals() {
  return apiProvider.getAll(urlConstants.REFERRALS_LIST);
}

export function getReferralUsers(params) {
  return apiProvider.get(urlConstants.REFERRALS_USERS, params);
}

export function sendInvitation(reqData) {
  return apiProvider.post(urlConstants.REFERRALS_INVITATION_SEND, reqData);
}

export function reNewGig(reqData) {
  return apiProvider.post(urlConstants.RENEW_GIG, reqData);
}

export function duplicateJob(reqData) {
  return apiProvider.post(urlConstants.DUPLICATE_JOB, reqData);
}

export function duplicateGig(reqData) {
  return apiProvider.post(urlConstants.DUPLICATE_GIG, reqData);
}

export function getRecruiterDetails(id) {
  return apiProvider.get(urlConstants.RECRUITER_DETAILS, id);
}

export function deleteAccount() {
  return apiProvider.post(urlConstants.DELETE_ACCOUNT);
}

export function restoreAccount(reqData) {
  return apiProvider.post(urlConstants.RESTORE_ACCOUNT, reqData);
}

export function smartApply(reqData) {
  return apiProvider.post(urlConstants.SMART_APPLY, reqData);
}

export function boostJob(reqData) {
  return apiProvider.post(urlConstants.JOB_BOOST, reqData);
}

export function featureJob(reqData) {
  return apiProvider.post(urlConstants.JOB_FEATURE, reqData);
}

export function featureGig(reqData) {
  return apiProvider.post(urlConstants.GIG_FEATURE, reqData);
}

export function endJobReferral(referral_id) {
  return apiProvider.post(urlConstants.JOB_REFERRAL_END + referral_id);
}

export function otpVerify(reqData) {
  return apiProvider.post(urlConstants.OTP_VERIFY, reqData);
}

export function EmailOtpVerify(reqData) {
  return apiProvider.post(urlConstants.EMAIL_OTP_VERIFY, reqData);
}

export function skipProfile() {
  return apiProvider.getAll(urlConstants.SKIP_PROFILE);
}

export function saveSearchCriteria(reqData) {
  return apiProvider.post(urlConstants.SAVE_CRITERIA, reqData);
}

export function getSavedSearchCriteria() {
  return apiProvider.getAll(urlConstants.GET_SAVE_CRITERIAS);
}

export function deleteSavedSearchCriteria(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_SAVE_CRITERIA + `/${id}`);
}
export function deleteSavedCandidate(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_SAVE_CANDIDATE + `/${id}`);
}

export function saveCandidate(reqData) {
  return apiProvider.post(urlConstants.SAVE_CANDIDATE, reqData);
}

export function getSavedCandidates(queryParams) {
  return apiProvider.get(urlConstants.GET_SAVE_CANDIDATES, queryParams);
}




/// EVALUATOR

export function checkCandidateProStatus() {
  return apiProvider.getAll(urlConstants.CHECK_CANDIDATE_PRO_STATUS);
}

export function sendProRequest(reqData) {
  return apiProvider.post(urlConstants.SEND_CANDIDATE_PRO_REQUEST, reqData);
}

export function getEvaluationSlots(reqData) {
  return apiProvider.post(urlConstants.EVALUATION_SLOTS, reqData);
}




export function updateEvaluatorSlots(reqData) {
  return apiProvider.post(urlConstants.EVALUATOR_UPDATE_SLOT, reqData);
}


export function bookAppointment(reqData) {
  return apiProvider.post(urlConstants.BOOK_APPOINTMENT, reqData);
}

export function getEvaluatorSlots() {
  return apiProvider.getAll(urlConstants.EVALUATOR_GET_SLOT);
}

export function getEvaluatorCalendar(params) {
  return apiProvider.getAll(params ? urlConstants.EVALUATOR_CALENDAR + params : urlConstants.EVALUATOR_CALENDAR);
}

export function getEvaluatorDashboard(params) {
  const url = params ? urlConstants.EVALUATOR_DASHBOARD + params : urlConstants.EVALUATOR_DASHBOARD;
  return apiProvider.getAll(url);
}

export function getUserInterviews(q = "") {
  return apiProvider.get(urlConstants.GET_USER_INTERVIEWS, q);
}

export function canUserBookAppointment(q = "") {
  return apiProvider.get(urlConstants.CHECK_EVALUATION_APPOINTMENT, q);
}

export function updateAppointmentStatus(reqData) {
  return apiProvider.post(urlConstants.UPDATE_APPOINTMENT_STATUS, reqData);
}

export function getMeetingDetails(id) {
  return apiProvider.get(urlConstants.GET_INTERVIEW_DETAILS, id);
}

export function saveEvaluationFeedback(reqData) {
  return apiProvider.post(urlConstants.EVALUATION_FEEDBACK, reqData);
}

export function getPartnerCandidates(params = "") {
  return apiProvider.getAll(urlConstants.PARTNER_CANDIDATES + params);
}

export function getPartnerCandidatesAppliedJobs(params = "") {
  return apiProvider.getAll(urlConstants.PARTNER_CANDIDATES_APPLIED_JOBS + params);
}

export function getPartnerCandidatesAppliedGigs(params = "") {
  return apiProvider.getAll(urlConstants.PARTNER_CANDIDATES_APPLIED_GIGS + params);
}

export function getPartnerDashboard() {
  return apiProvider.getAll(urlConstants.PARTNER_DASHBOARD);
}

export function postResume(data) {
  return apiProvider.post(urlConstants.RESUME_DATA, data);
}

export function getResume(data) {
  return apiProvider.getAll(urlConstants.RESUME_DATA, data);
}


export function getFeaturedJobSlotsData(q = "") {
  return apiProvider.get(urlConstants.FEATURED_JOB_SLOTS, q);
}

export function buySlots(data) {
  return apiProvider.post(urlConstants.FEATURED_JOB_BUY_SLOTS, data);
}

export function checkSlots(data) {
  return apiProvider.post(urlConstants.FEATURED_JOB_CHECK_SLOTS, data);
}

export function updateJobSlot(data) {
  return apiProvider.post(urlConstants.UPDATE_JOB_SLOT, data);
}

export function addJobImpression(data) {
  return apiProvider.post(urlConstants.ADD_JOB_IMPRESSION, data);
}

export function getJobActivity(jobId) {
  return apiProvider.get(urlConstants.GET_JOB_ACTIVITY, jobId);
}

export function getFeaturedGigSlotsData(q = "") {
  return apiProvider.get(urlConstants.FEATURED_GIG_SLOTS, q);
}

export function buyGigSlots(data) {
  return apiProvider.post(urlConstants.FEATURED_GIG_BUY_SLOTS, data);
}

export function checkGigSlots(data) {
  return apiProvider.post(urlConstants.FEATURED_GIG_CHECK_SLOTS, data);
}

export function updateGigSlot(data) {
  return apiProvider.post(urlConstants.UPDATE_GIG_SLOT, data);
}

export function addGigImpression(data) {
  return apiProvider.post(urlConstants.ADD_GIG_IMPRESSION, data);
}

export function getGigActivity(gigId) {
  return apiProvider.get(urlConstants.GET_GIG_ACTIVITY, gigId);
}

export function downloadReport(q = "") {
  return apiProvider.get(urlConstants.DOWNLOAD_REPORTS, q);
}

export function getJobSlotHistory(params) {
  return apiProvider.getAll(urlConstants.GET_JOB_SLOTS_HISTORY, params);
}

export function getGigSlotHistory(params) {
  return apiProvider.getAll(urlConstants.GET_GIG_SLOTS_HISTORY, params);
}

export function sendResumeOtp(data) {
  return apiProvider.post(urlConstants.SEND_RESUME_OTP, data);
}

export function verifyResumeOtp(data) {
  return apiProvider.post(urlConstants.VERIFY_RESUME_OTP, data);
}

export function resumeCandidateRegister(data) {
  return apiProvider.post(urlConstants.RESUME_CANDIDATE_REGISTER, data);
}

export function resumeVerifyPayment(data) {
  return apiProvider.post(urlConstants.RESUME_VERIFY_PAYMENT, data);
}

export function resumeByAI(data) {
  return apiProvider.post(urlConstants.RESUME_BY_AI, data);
}