import { useEffect, useState, useRef } from "react";
import "./JobDetail.scss";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as ApiHelper from "@/services/apiHelper";
import * as toast from "@/wrapper/toast";

import { css } from "@emotion/react";
import * as storageConstants from "@/constants/storageConstants";
import * as commonServices from "@/services/common";
import { isReportedJob } from "@/utils/job";
import { analyticsConstant } from "../../constants/analyticsConstant";
import { trackJOB } from "../../helpers/analytics";
import JobDetailsLayouts from "./JobDetailsLayouts";
import { isNullOrEmpty } from "../../utils/stringUtils";
import { viewSimilarCandidates } from "../../services/common";
import { isCompanyAdmin } from "../../utils/authUtil";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function JobDetail() {
  const ref = useRef(null);


  let [loading, setLoading] = useState(false);
  let [color] = useState("#000000");

  let params = useParams();
  const job_id = params.job_id;
  const navigate = useNavigate();
  const currentUser = commonServices.getLocalData(storageConstants.PROFILE);
  const currentUserRole = commonServices.getLocalData(
    storageConstants.USER_ROLE
  );

  const [jobDetails, setJobDetails] = useState();
  const [showModalShare, setShowModalShare] = useState(false);
  const [showModalApply, setShowModalApply] = useState(false);
  const [showModalReport, setShowModalReport] = useState(false);
  const [showModalClose, setShowModalClose] = useState(false);
  const [showModalReferral, setShowModalReferral] = useState(false);
  const [showModalReferralData, setShowModalReferralData] = useState(false);
  const [referralData, setReferralData] = useState({});
  const [keyword, setKeyword] = useState(null);
  let [searchParams] = useSearchParams();

  const [showFeatureJobModal, setShowFeatureJobModal] = useState(false);
  const [featureJobDuration, setFeatureJobDuration] = useState();

  const [selectedCriterias, setSelectedCriterias] = useState([]);
  const [showSimilarCandidatesModal, setShowSimilarCandidatesModal] = useState(null);

  const isSimilarCriteriaChecked = (type) => {
    return selectedCriterias.includes(type);
  };

  const showSimilarSearch = () => {
    if (selectedCriterias.length < 1) {
      toast.warn("Please select at least one criteria");
      return;
    }

    const data = showSimilarCandidatesModal;
    let newData = {};

    newData.skills = data?.skills?.length > 0 ? data?.skills : [];
    newData.location_id = data?.location_id;
    newData.full_name = data?.first_name + ' ' + data?.last_name;
    newData.id = data?.candidate_id;

    viewSimilarCandidates(newData, setLoading, isSimilarCriteriaChecked);
  }

  useEffect(() => {
    let q = searchParams.get("q");
    setKeyword(q);
  }, [searchParams]);

  const [masterData, setMasterData] = useState();

  useEffect(() => {
    const masterData = commonServices.getLocalData(storageConstants.DATA);
    setMasterData(masterData);
  }, [])

  const disableFeatureJobButton = () => {
    return jobDetails.user_remaining_amount < featureJobDuration * masterData?.system_config?.searchpage_feature_job_slot_price?.value;
  }

  const jobDetail = async () => {
    setLoading(true);
    await ApiHelper.getJobDetail(job_id).then((response) => {
      if (response.isSuccess === true) {
        try {
          trackJOB(analyticsConstant.VIEW, job_id, response.data.title);
        } catch { }
        setJobDetails(response.data);
        if (response.data.referral && response.data.referral.length > 0) {
          setReferralData(response.data.referral[0]);
        }
      } else if (response.code === 400) {
        toast.warn(response.message);
        navigate("/access-denied");
      } else {
        toast.warn(response.message);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    jobDetail();
  }, []);

  const reNewJob = () => {
    const formData = new FormData();
    formData.append("job_id", jobDetails.eid);
    setLoading(true);
    ApiHelper.reNewJob(formData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        toast.success(response.message);
        jobDetail();
      } else {
        toast.warn(response.message);
      }
      jobDetail();
    });
  };

  const duplicateJob = () => {
    const formData = new FormData();
    formData.append("job_id", jobDetails.eid);
    setLoading(true);
    ApiHelper.duplicateJob(formData).then((response) => {
      if (response.isSuccess === true) {
        const draftJobId = response?.data?.eid;
        toast.success(response.message);
        isNullOrEmpty(draftJobId) ? navigate("/posted?tab=draft") : navigate("/job/edit/" + draftJobId);
      } else {
        toast.warn(response.message);
      }
      setLoading(false);
    });
  };

  const closeJob = (status) => {
    const formData = new FormData();
    formData.append("job_id", jobDetails.eid);
    formData.append("status", status);
    setLoading(true);
    ApiHelper.changeJobStatus(formData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
      jobDetail();
    });
  };

  const endJobReferral = (referral_id) => {
    setLoading(true);
    ApiHelper.endJobReferral(referral_id).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
      jobDetail();
    });
  };

  const changeApplicantStatus = (candidate_id, status) => {
    const formData = new FormData();
    formData.append("job_id", jobDetails.eid);
    formData.append("candidate_id", candidate_id);
    formData.append("recruiter_action", status);
    setLoading(true);
    ApiHelper.changeApplicantStatus(formData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
      jobDetail();
    });
  };

  const saveJob = () => {
    const formData = new FormData();
    var is_saved = 1 - jobDetails.is_saved;
    formData.append("job_id", jobDetails.eid);
    formData.append("saved", is_saved);
    setLoading(true);
    ApiHelper.applySaveJob(formData).then((response) => {
      setLoading(false);
      if (response.isSuccess === true) {
        toast.success(response.message);
      } else {
        toast.warn(response.message);
      }
      jobDetail();
    });
  };

  const openShareModal = () => {
    setShowModalShare((prev) => !prev);
  };

  const openApplyModal = () => {
    setShowModalApply((prev) => !prev);
  };

  const openReportModal = () => {
    if (!isReportedJob(jobDetails)) {
      setShowModalReport((prev) => !prev);
    }
  };

  const showReportedMessage = () => {
    toast.warn("You have already reported this job!");
  };

  const referAFriend = () => {
    if (
      referralData?.referral_data !== undefined &&
      referralData?.referral_data !== null &&
      referralData?.referral_data !== "" &&
      (referralData?.referral_data?.isSkill ||
        referralData?.referral_data?.isLocation ||
        referralData?.referral_data?.isExperience ||
        referralData?.referral_data?.otherRequirements !== "")
    ) {
      setShowModalReferralData(!showModalReferralData);
    } else {
      setShowModalReferral(!showModalReferral);
    }
  };

  const addProfileTracking = (
    is_profile_viewed = 0,
    is_profile_downloaded = 0,
    user_id
  ) => {
    var formData = new FormData();
    formData.append("candidate_id", user_id);
    formData.append("is_profile_viewed", is_profile_viewed);
    formData.append("is_profile_downloaded", is_profile_downloaded);
    formData.append("company_job_id", jobDetails?.eid);
    ApiHelper.addProfileTrackingData(formData).then((response) => {
      console.log(response);
    });
  };

  const [buttonLoading, setButtonLoading] = useState(false);

  const submitFeatureJob = (job_id, duration) => {
    if (isNullOrEmpty(duration)) {
      toast.warn("Please Select a Valid Duration");
      return;
    } else if (isNullOrEmpty(job_id)) {
      toast.warn("Invalid Job ID");
      return;
    } else {
      setButtonLoading(true);
      const data = { job_id, duration: parseInt(duration) };

      ApiHelper.featureJob(data).then(response => {
        if (response.isSuccess === true) {
          toast.success(response.message);
          setButtonLoading(false);
          setShowFeatureJobModal(false);
          jobDetail();
        } else {
          setButtonLoading(false);
          toast.warn(response.message);
        }
      })
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('action') === 'buy') {
      buyMoreCash();
    }
  }, []);

  const buyMoreCash = () => {
    if (isCompanyAdmin()) {
      navigate('/company/users', {
        state: {
          showPaymentGateay: true
        }
      });
    } else {
      toast.warn("Ask your Company Admin for more TT Cash");
    }
  }

  return (
    <JobDetailsLayouts
      jobDetails={jobDetails}
      color={color}
      override={override}
      showModalApply={showModalApply}
      setShowModalApply={setShowModalApply}
      jobDetail={jobDetail}
      showModalReport={showModalReport}
      setShowModalReport={setShowModalReport}
      showModalClose={showModalClose}
      setShowModalClose={setShowModalClose}
      showModalShare={showModalShare}
      setShowModalShare={setShowModalShare}
      showModalReferral={showModalReferral}
      setShowModalReferral={setShowModalReferral}
      showModalReferralData={showModalReferralData}
      setShowModalReferralData={setShowModalReferralData}
      keyword={keyword}
      loading={loading}
      referralData={referralData}
      currentUser={currentUser}
      navigate={navigate}
      commonServices={commonServices}
      referAFriend={referAFriend}
      openApplyModal={openApplyModal}
      closeJob={closeJob}
      endJobReferral={endJobReferral}
      reNewJob={reNewJob}
      saveJob={saveJob}
      openReportModal={openReportModal}
      duplicateJob={duplicateJob}
      ref={ref}
      addProfileTracking={addProfileTracking}
      job_id={job_id}
      changeApplicantStatus={changeApplicantStatus}
      openShareModal={openShareModal}
      showFeatureJobModal={showFeatureJobModal}
      setShowFeatureJobModal={setShowFeatureJobModal}
      submitFeatureJob={submitFeatureJob}
      featureJobDuration={featureJobDuration}
      setFeatureJobDuration={setFeatureJobDuration}
      buttonLoading={buttonLoading}
      masterData={masterData}
      disableFeatureJobButton={disableFeatureJobButton}
      setLoading={setLoading}
      selectedCriterias={selectedCriterias}
      setSelectedCriterias={setSelectedCriterias}
      showSimilarCandidatesModal={showSimilarCandidatesModal}
      setShowSimilarCandidatesModal={setShowSimilarCandidatesModal}
      isSimilarCriteriaChecked={isSimilarCriteriaChecked}
      showSimilarSearch={showSimilarSearch}
    />
  );
}

export default JobDetail;