import { Mixpanel } from '@/services/mixPanel.js';
import { analyticsConstant } from '../constants/analyticsConstant';


export const setUser = (data) => {
    Mixpanel.identify(data.email)
    Mixpanel.people.set({
        id: data?.id,
        first_name: data?.first_name,
        full_name: data?.full_name,
        is_mobile_verified: data?.is_mobile_verified,
        is_profile_completed: data?.is_profile_completed,
        role: data?.role_type,
        email: data?.email
    });
}


export const trackEvent = (eventName, data) => {
    Mixpanel.track(eventName, { ...data });
}



export const trackLogin = (step, data = "") => {

    trackEvent(analyticsConstant.LOGIN + (step ? "_" + step : ""), {
        data: data
    })
}

export const trackCandidateOnboarding = (step, data = "") => {

    trackEvent(analyticsConstant.CANDIDATE_ONBOARDING + (step ? "_" + step : ""), {
        data: data
    })
}

export const trackEvaluatorOnboarding = (step, data = "") => {

    trackEvent(analyticsConstant.EVALUATOR_ONBOARDING + (step ? "_" + step : ""), {
        data: data
    })
}

export const trackCompanyOnboarding = (step, data = "") => {

    trackEvent(analyticsConstant.COMPANY_ONBOARDING + (step ? "_" + step : ""), {
        data: data
    })
}

export const trackJOB = (step, id = "", name = "") => {

    trackEvent(analyticsConstant.JOB + (step ? "_" + step : ""), {
        data: id,
        name: name
    })
}
export const trackGIG = (step, id = "", name = "") => {

    trackEvent(analyticsConstant.GIG + (step ? "_" + step : ""), {
        data: id,
        name: name
    })
}
