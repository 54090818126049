import axios from 'axios';
import { handleResponse, handleError } from './response';
import * as storageConstants from '../constants/storageConstants';
import * as commonServices from './common';

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = process.env.REACT_APP_BASE_URL;


export const getHeaders = () => {
  return {
    'Accept': 'application/json',
    'Authorization': commonServices.getLocalData(storageConstants.AUTH) !== null ? `Bearer ${commonServices.getLocalData(storageConstants.AUTH)}` : null,
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_CLIENT_ID
  };
};

/** @param {string} resource */
export const getAll = (resource, model = '') => {
  var headers = getHeaders();
  return axios
    .get(`${BASE_URL}${resource}${model}`, { headers: headers })
    .then(handleResponse)
    .catch(handleError);
};


export const post = (resource, model) => {
  var headers = getHeaders();
  return axios
    .post(`${BASE_URL}${resource}`, model, { headers: headers })
    .then(handleResponse)
    .catch(handleError);
};

export const get = (resource, id) => {
  var headers = getHeaders();
  return axios
    .get(`${BASE_URL}${resource}${id}`, { headers: headers })
    .then(handleResponse)
    .catch(handleError);
};

export const deleteAPI = (id) => {
  var headers = getHeaders();
  return axios
    .delete(`${BASE_URL}${id}`, { headers: headers })
    .then(handleResponse)
    .catch(handleError);
};

export const verifyEmail = (resource) => {
  var headers = getHeaders();
  return axios
    .get(`${resource}`, { headers: headers })
    .then(handleResponse)
    .catch(handleError);
};