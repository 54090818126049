import React, { useEffect, useState, useRef } from "react";
import jobVacancy from "@/Assets/images/job-vacancy.png";
import featureIcon from "@/Assets/images/feature-job-icon.png";
import checkIcon from "@/Assets/images/check-icon.png";
import * as commonServices from "@/services/common";
import ModalBuyFeaturedGigSlots from "../../Modals/ModalBuyFeaturedGigSlots";
import * as ApiHelper from "@/services/apiHelper";
import * as toast from "@/wrapper/toast";
import { createSlugFromString, getSkillsString } from "@/utils/stringUtils";
import { useNavigate } from "react-router-dom";
import ModalFeaturedGigActivity from "../../Modals/ModalFeaturedGigActivity";
import Button from "../../_widgets/Button/Button";
import Table from "../../_widgets/Table/Table";
import { Form } from "react-bootstrap";
import CustomTooltip from '../../../utils/CustomTooltip'

function FeaturedGigs({
  loading,
  setLoading,
  openBuyCashModal,
  setActiveTab,
  setlastTab,
}) {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const [showActivityModal, setShowActivityModal] = useState(false);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;

  const [gigId, setGigId] = useState(null);
  function getSlotDates(year, month) {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    let startDate, endDate;

    if (currentDay < 15) {
      startDate = new Date(year, month - 1, 1);
      endDate = new Date(year, month - 1, 15);
    } else {
      startDate = new Date(year, month - 1, 16);
      endDate = new Date(year, month, 0); // Last day of the month
    }

    return { startDate, endDate };
  }

  function formatDate(date) {
    if (!date) return '';
    return date.toLocaleDateString(undefined, { day: '2-digit', month: 'long' });
  }
  const { startDate, endDate } = getSlotDates(year, month);

  const getData = async () => {
    setLoading(true);
    var queryString = "?pageNumber=" + activePage;
    await ApiHelper.getFeaturedGigSlotsData(queryString).then((response) => {
      if (response.isSuccess === true) {
        setData(response.data);
      } else {
        toast.warn(response.message);
      }
      setLoading(false);
    });
  };

  const onGigStatusChange = async (e, gig, page) => {
    let status = e.target.checked ? "activated" : "deactivated";
    let formData = new FormData();
    formData.append("gig_id", gig?.eid);
    formData.append("page", page);
    formData.append("status", status);
    setLoading(true);
    await ApiHelper.updateGigSlot(formData).then((response) => {
      if (response.isSuccess === true) {
        toast.success(response.message);
        getData();
      } else {
        toast.warn(response.message);
      }
    });
    setLoading(false);
  };

  const isActivated = (gig, page) => {
    if (page === "homepage") {
      return gig?.featured_slot_homepage_data?.status === "activated";
    } else {
      return gig?.featured_slot_searchpage_data?.status === "activated";
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const viewActivity = (gig_id) => {
    setGigId(gig_id);
    setShowActivityModal(true);
  };

  var tableColumns = ['Gig ID', 'Gig', 'Skills', '', 'Feature on Homepage', 'Feature on Searchpage', ''];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    var row = [];
    var row = data?.company_gigs && data?.company_gigs?.data.map((gig, i) => {
      var data = [];
      data.push(<p onClick={() => { navigate('/gig/details/' + gig?.eid + "/" + createSlugFromString(gig?.title)) }}>ID-{gig?.eid}</p>)
      data.push(gig?.title);
      data.push(getSkillsString(gig?.skills));
      data.push(<span title="Click to edit gig details!" onClick={() => navigate('/gig/edit/' + gig?.eid)}>
        <img style={{ width: "1.5em" }} onError={commonServices.imgError} className="edit" src=".././Assets/svgs/edit.svg" alt="" />
      </span>);
      data.push(<label className="switch ms-5">
        <Form>
          <Form.Check // prettier-ignore
            type="switch"
            onChange={(e) => onGigStatusChange(e, gig, "homepage")}
            checked={isActivated(gig, "homepage")}
            id="auto-apply"
          />
        </Form>
      </label>);
      data.push(
        <label className="switch ms-5">
          <Form>
            <Form.Check // prettier-ignore
              type="switch"
              onChange={(e) => onGigStatusChange(e, gig, 'searchpage')}
              checked={isActivated(gig, 'searchpage')}
              id="auto-apply"
            />
          </Form>
        </label>)
      data.push(<p className='hand-hover' onClick={() => viewActivity(gig?.eid)} style={{ fontSize: "0.875em", fontWeight: "500", color: "var(--green)" }}>View Activity</p>)
      return {
        data: data
      }
    });

    setTableData(row);
  }, [data]);

  const [activePage, setActivePage] = useState(1);
  const handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    setActivePage(activePage);
  };

  useEffect(() => {
    getData();
  }, [activePage]);

  return (
    <>
      {loading ? null : (
        <div className="feature-job">
          <ModalBuyFeaturedGigSlots
            showModal={showModal}
            setShowModal={setShowModal}
            data={data}
            getData={getData}
            openBuyCashModal={openBuyCashModal}
            setActiveTab={setActiveTab}
          />
          <ModalFeaturedGigActivity
            showModal={showActivityModal}
            setShowModal={setShowActivityModal}
            gigId={gigId}
          />
          {data?.company_slots < 1 ? (
            <>
              <div className="row text-center section_1">
                <img src={jobVacancy} />
                <h5 className="my-3">Featured Gigs</h5>
                <p>
                  Please choose the featured gig package that best fit your
                  business needs.
                </p>
              </div>

              <div className="row text-center section_2 mt-3">
                <div className="col-lg-1 col-md-1 col-sm-3 m-auto">
                  <img src={featureIcon} />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-9 mb-1 text-start">
                  <div className="row">
                    <h5
                      style={{
                        fontSize: "1em",
                        fontWeight: "600",
                        color: "var(--green)",
                      }}
                    >
                      Home Page
                    </h5>
                  </div>
                  <div className="row">
                    <h4
                      className="my-1"
                      style={{
                        fontSize: "1.25em",
                        fontWeight: "600",
                        color: "#101828",
                      }}
                    >
                      {data?.homepage_feature_gig_slot_price} TT Cash / Slot
                    </h4>
                  </div>
                  <div className="row">
                    <p
                      style={{
                        fontSize: "0.875em",
                        fontWeight: "400",
                        color: "#475467",
                      }}
                    >
                      For 15 Days
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 mb-1 text-start">
                  <div className="row">
                    <div className="d-flex">
                      <img src={checkIcon} />
                      <p className="item-info">Access to all basic features</p>
                    </div>
                    <div className="d-flex">
                      <img src={checkIcon} />
                      <p className="item-info">Basic reporting and analytics</p>
                    </div>

                    <div className="d-flex">
                      <img src={checkIcon} />
                      <p className="item-info">Up to 10 individual users</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 m-auto text-end">
                  <Button
                    buttonType="primary"
                    onClick={() => setShowModal(true)}
                    text="Buy Slots"
                  />
                </div>
              </div>
              <div className="row text-center section_2 mt-3">
                <div className="col-lg-1 col-md-1 col-sm-3 m-auto">
                  <img src={featureIcon} />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-9 mb-1 text-start">
                  <div className="row">
                    <h5
                      style={{
                        fontSize: "1em",
                        fontWeight: "600",
                        color: "var(--green)",
                      }}
                    >
                      Search Page
                    </h5>
                  </div>
                  <div className="row">
                    <h4
                      className="my-1"
                      style={{
                        fontSize: "1.25em",
                        fontWeight: "600",
                        color: "#101828",
                      }}
                    >
                      {data?.searchpage_feature_gig_slot_price} TT Cash / Slot
                    </h4>
                  </div>
                  <div className="row">
                    <p
                      style={{
                        fontSize: "0.875em",
                        fontWeight: "400",
                        color: "#475467",
                      }}
                    >
                      For 15 Days
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 mb-1 text-start">
                  <div className="row">
                    <div className="d-flex">
                      <img src={checkIcon} />
                      <p className="item-info">Access to all basic features</p>
                    </div>
                    <div className="d-flex">
                      <img src={checkIcon} />
                      <p className="item-info">Basic reporting and analytics</p>
                    </div>

                    <div className="d-flex">
                      <img src={checkIcon} />
                      <p className="item-info">Up to 10 individual users</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 m-auto text-end">
                  <Button
                    buttonType="primary"
                    onClick={() => setShowModal(true)}
                    text="Buy Slots"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="">
              {data?.company_expiring_slots_in_7_days > 0 ? (
                <div className="row" style={{ padding: "0px 16px" }}>
                  <div
                    className="alert alert-info mt-2 error-bar"
                    role="alert"
                    style={{
                      color: "#FC3400",
                      backgroundColor: "#FADEDD",
                      borderColor: "#FADEDD",
                      fontWeight: "700",
                    }}
                  >
                    {data?.company_expiring_slots_in_7_days} Slots are expiring
                    in the next 7 days
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <CustomTooltip text={`Slots available for ${formatDate(startDate)} - ${formatDate(endDate)}`}>
                    <div
                      className="row text-center section_3 mt-3"
                      style={{ minHeight: "90px" }}
                    >
                      <div
                        className="col-6"
                        style={{ textAlign: "left", margin: "auto" }}
                      >
                        <p
                          style={{
                            fontSize: "1em",
                            fontWeight: "500",
                            color: "#2E2C34",
                          }}
                        >
                          Current Slots
                        </p>
                        {/* <p style={{ fontSize: "1em", fontWeight: "700", color: "#2E2C34" }}>Homepage</p> */}
                      </div>
                      <div className="col-6" style={{ margin: "auto" }}>
                        <p
                          style={{
                            fontSize: "2em",
                            fontWeight: "700",
                            color: "#2E2C34",
                          }}
                        >
                          {data?.company_active_slots > 0
                            ? data?.company_active_slots
                            : 0}
                        </p>
                      </div>
                    </div>
                  </CustomTooltip>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12">
                  <CustomTooltip text={`Slots in use for ${formatDate(startDate)} - ${formatDate(endDate)}`}>
                    <div
                      className="row text-center section_3 mt-3"
                      style={{ minHeight: "90px" }}
                    >
                      <div
                        className="col-6"
                        style={{ textAlign: "left", margin: "auto" }}
                      >
                        <p
                          style={{
                            fontSize: "1em",
                            fontWeight: "500",
                            color: "#2E2C34",
                          }}
                        >
                          Slots in Use
                        </p>
                        {/* <p style={{ fontSize: "1em", fontWeight: "700", color: "#2E2C34" }}>Search Result Page</p> */}
                      </div>
                      <div className="col-6" style={{ margin: "auto" }}>
                        <p
                          style={{
                            fontSize: "2em",
                            fontWeight: "700",
                            color: "#2E2C34",
                          }}
                        >
                          {data?.company_used_slots > 0
                            ? data?.company_used_slots
                            : 0}
                        </p>
                      </div>
                    </div>
                  </CustomTooltip>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <CustomTooltip text={`Slots available for ${formatDate(startDate)} - ${formatDate(endDate)}`}>
                    <div
                      className="row text-center section_3 mt-3"
                      style={{ minHeight: "90px" }}
                    >
                      <div
                        className="col-6"
                        style={{ textAlign: "left", margin: "auto" }}
                      >
                        <p
                          style={{
                            fontSize: "1em",
                            fontWeight: "500",
                            color: "#2E2C34",
                          }}
                        >
                          Available Slots
                        </p>
                      </div>
                      <div className="col-6" style={{ margin: "auto" }}>
                        <p
                          style={{
                            fontSize: "2em",
                            fontWeight: "700",
                            color: "#2E2C34",
                          }}
                        >
                          {data?.company_homepage_unused_slots +
                            data?.company_searchpage_unused_slots}
                        </p>
                      </div>
                    </div>
                  </CustomTooltip>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12">
                  <CustomTooltip text={`All purchased slots after ${formatDate(endDate)}`}>
                    <div
                      className="row text-center section_3 mt-3"
                      style={{ minHeight: "90px" }}
                    >
                      <div
                        className="col-6"
                        style={{ textAlign: "left", margin: "auto" }}
                      >
                        <p
                          style={{
                            fontSize: "1em",
                            fontWeight: "500",
                            color: "#2E2C34",
                          }}
                        >
                          Future Slots
                        </p>
                      </div>
                      <div className="col-6" style={{ margin: "auto" }}>
                        <p
                          style={{
                            fontSize: "2em",
                            fontWeight: "700",
                            color: "#2E2C34",
                          }}
                        >
                          {data?.company_total_active_slots}
                        </p>
                      </div>
                    </div>
                  </CustomTooltip>
                </div>

                <div className="mt-3">
                  <Button
                    style={{ float: "right" }}
                    buttonType="primary"
                    onClick={() => setShowModal(true)}
                    text="Buy More Slots"
                  />
                </div>

                {data?.company_gigs && data?.company_gigs?.data.length > 0 ? (
                  <div className="company-fix-table common_items mt-5">
                    <h4
                      style={{
                        fontSize: "1em",
                        fontWeight: "700",
                        color: "#263238",
                        marginBottom: "1.5rem",
                      }}
                    >
                      Gig Lists
                    </h4>
                    <Table
                      columns={tableColumns}
                      data={tableData}
                      activePage={activePage}
                      handlePaginationChange={handlePaginationChange}
                      pagination={data?.company_gigs}
                    />
                  </div>
                ) : (
                  <div className="no_gigs">
                    <img
                      onError={commonServices.imgError}
                      src="@/Assets/svgs/search_no_result.svg"
                      alt=""
                    />
                    <h2>No gig(s) found!</h2>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default FeaturedGigs;
