import React, { useEffect, useRef, useState } from 'react'

import Text from '../_widgets/Text/Text';
import Button from '../_widgets/Button/Button';
import Checkbox from '../_widgets/Checkbox/Checkbox';
import Link from "../_widgets/Link/Link"

import * as toast from "@/wrapper/toast";


import logo from "@/Assets/svgs/logo.svg"

import { GoMail } from "react-icons/go"
import { BiLockOpenAlt } from "react-icons/bi"

import googleLogo from "@/Assets/images/google-logo.png";

import * as erros from '@/constants/stringConstants';
import * as storageConstants from '@/constants/storageConstants';
import * as ApiHelper from '@/services/apiHelper';
import * as commonServices from '@/services/common';
import { APP_NAME } from '@/constants/storageConstants';
import { updateHeaderMessageIcon } from '@/utils/messageUtil';
import { READ, UNREAD } from '@/constants/messageConstants';
import { isLoggedIn } from '@/utils/authUtil';
import { BUTTON_TEXT_OK } from "@/constants/stringConstants";
import ModalRestoreAccount from "../Modals/ModalRestoreAccount";
import RegistrationSteps from '../Register/RegistrationSteps/RegistrationSteps';
import { useNavigate, useSearchParams } from 'react-router-dom'
import Loader from '../Common/Loader'

import * as analytics from '@/helpers/analytics';
import { trackLogin } from '../../helpers/analytics';
import { analyticsConstant } from '../../constants/analyticsConstant';
import Input from '../_widgets/Input/Input';
import IconResource from '../_widgets/IconResource/IconResource';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { initiateFirebase } from '../../config/firebase';

const Login = ({ onClose, isHeaderUpdate, setIsHeaderUpdate }) => {

    var url_string = window.location.href;
    var url = new URL(url_string);
    var isVerified = url.searchParams.get("verify");
    var msg = url.searchParams.get("msg");
    var verifyUrl = url.searchParams.get("url");
    var verifyUrlHash = url.searchParams.get("hash");
    var verifyUrlSignature = url.searchParams.get("signature");
    var redirect = url.searchParams.get("redirect");

    const [passwordShown, setPasswordShown] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [showModalError, setShowModalError] = useState(false);
    const [showModalRestore, setShowModalRestore] = useState(false);
    const [showModalApplyJob, setShowModalApplyJob] = useState(false);
    const [showStep, setShowStep] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [modalErrorData, setModalErrorData] = useState();
    const [profile, setProfile] = useState([]);
    const [referredData, setReferredData] = useState([]);

    let [loading, setLoading] = useState(false);
    let [color] = useState("#000000");

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    const navigate = useNavigate();

    const changeRegister = () => {
        navigate("/register");
    }

    const changeForget = () => {
        navigate("/forgot-password");
    }

    useEffect(() => {
        initiateFirebase()
    }, [])

    const getGoogleLogin = (e) => {
        e.preventDefault();

        const auth = getAuth();
        const provider = new GoogleAuthProvider();

        signInWithPopup(auth, provider)
            .then(result => {
                const user = result.user;

                const data = {
                    "first_name": user.displayName.split(" ")[0],
                    "last_name": user.displayName.split(" ")[1],
                    "email": user.email,
                    "provider_id": user.providerId,
                    "uid": user.uid,
                    "token": user.accessToken
                }

                setLoading(true);
                ApiHelper.googleLoginV2(data)
                    .then(response => {
                        setLoading(false);

                        if (response.isSuccess === true) {

                            trackLogin("SUCCESS")
                            if (response.data !== null) {

                                if (response.data.profile.is_profile_completed) {
                                    toast.success(response.message);
                                    commonServices.storeLocalData(storageConstants.AUTH, response.data.token);
                                    commonServices.storeLocalData(storageConstants.USER_ROLE, response.data.profile.role_type);
                                    commonServices.storeLocalData(storageConstants.PROFILE, response.data.profile);
                                    setIsHeaderUpdate(!isHeaderUpdate);
                                    setIsHeaderUpdate(!isHeaderUpdate);
                                    onClose();
                                    if (response?.data?.user_unread_messages > 0) {
                                        updateHeaderMessageIcon(UNREAD);
                                    } else {
                                        updateHeaderMessageIcon(READ);
                                    }
                                } else if (!response.data.profile.is_profile_completed) {

                                    onClose();
                                    navigate('/register', {
                                        state: {
                                            selectedProfile: response?.data?.role_type === 'EV' ? 'Evaluator' : 'candidate',
                                            profile: response.data.profile,
                                            currentStep: 1,
                                            showParserModal: true
                                        }
                                    });

                                    return;
                                } else if (!response.data.profile.email_verified_at) {
                                    if (response.data.profile.role_type === 'CA' || response.data.profile.role_type === 'CU') {
                                        onClose();
                                        navigate('/register?email=' + response.data.profile.email);
                                        return;
                                    }
                                    setCurrentStep(2);
                                    setShowStep(true);
                                    onClose();
                                    navigate('/onboarding', {
                                        state: {
                                            currentStep: 2,
                                            profile: response.data.profile,
                                            isHeaderUpdate: isHeaderUpdate,
                                            showStep: true,
                                            showModalApplyJob,
                                            referredData,
                                        }
                                    });
                                    return;
                                }

                                var nextRedirect = null;
                                if (response.data.token && (response.data.profile.role_type === 'CD' || response.data.profile.role_type === 'EV') && response.data.profile !== null && response.data.profile.progress < 100) {
                                    setShowModalApplyJob(response?.data?.profile?.show_apply_popup);
                                    setReferredData(response?.data?.profile?.referral_data);
                                    nextRedirect = '/workprofile?profileInProgress=1';
                                } else if (response.data.token && response.data.profile.role_type === 'EV' && response.data.profile !== null && response.data.profile.progress >= 100) {
                                    nextRedirect = '/evaluator/dashboard';
                                } else if (response.data.token && response.data.profile.role_type === 'CA') {
                                    nextRedirect = '/company-dashboard';
                                } else if (response.data.token && response.data.profile.role_type === 'PA') {
                                    nextRedirect = '/company/edit';
                                }
                                if (redirect !== null) {
                                    navigate(redirect);
                                }else if(nextRedirect !== null){
                                    navigate(nextRedirect);
                                } else {
                                    navigate('/',
                                        {
                                            state: {
                                                "nextStep": response.data.next_step,
                                                "message": response.data.message
                                            }
                                        }
                                    );
                                }
                            }
                        }
                        else {
                            if (response.code === 203) {
                                setShowModalRestore(!showModalRestore);
                                trackLogin("RESTORE_POPUP")
                            } else if (response.code === 201) {
                                setShowModalError(true);
                                setModalErrorData({
                                    ...modalErrorData,
                                    'heading': "Account Deleted",
                                    'paragraph': response.message,
                                    'buttonText': BUTTON_TEXT_OK,
                                });
                            } else {
                                toast.warn(response.message);
                                if (response.data !== undefined && (response.data.length > 0 || response.data.length === undefined)) {
                                    if (response.data.show_email_screen) {
                                        if (response.data.profile.role_type === 'CA' || response.data.profile.role_type === 'CU') {
                                            onClose();
                                            navigate('/register?email=' + response.data.profile.email);
                                            return;
                                        }
                                        setCurrentStep(3);
                                        setShowStep(true);
                                        onClose();
                                        navigate('/onboarding', {
                                            state: {
                                                currentStep: 3,
                                                profile: response.data.profile,
                                                isHeaderUpdate: isHeaderUpdate,
                                                showStep: true,
                                                showModalApplyJob,
                                                referredData,
                                            }
                                        });
                                        return

                                    } else if (!response.data.is_profile_completed) {
                                        setCurrentStep(1);
                                        setShowStep(true);
                                        onClose();

                                        navigate('/onboarding', {
                                            state: {
                                                currentStep: 1,
                                                selectedProfile: response?.data?.role_type === 'EV' ? 'Evaluator' : 'Candidate',
                                                profile: response.data.profile,
                                                isHeaderUpdate: isHeaderUpdate,
                                                showStep: true,
                                                showModalApplyJob,
                                                referredData,
                                            }
                                        });
                                        return;
                                    } else if (!response.data.profile.email_verified_at) {
                                        onClose();
                                        navigate('/register?email=' + response.data.profile.email);
                                        return;
                                    } else if (!response.data.is_mobile_verified) {
                                        setCurrentStep(3);
                                        setShowStep(true);
                                        onClose();
                                        navigate('/onboarding', {
                                            state: {
                                                currentStep: 3,
                                                profile: response.data.profile,
                                                isHeaderUpdate: isHeaderUpdate,
                                                showStep: true,
                                                showModalApplyJob,
                                                referredData,
                                            }
                                        });
                                        return;
                                    } else {
                                        trackLogin(analyticsConstant.FAILED, response.message)
                                    }
                                }
                            }
                        }
                    });
            }).catch((error) => {
                toast.warn(error.message);
            });
    }

    const changeHome = async (e) => {
        e.preventDefault();

        if (!email) {
            toast.warn(erros.EMAIL_REQUIRED)
            trackLogin(analyticsConstant.VALIDATION_ERROR, erros.EMAIL_REQUIRED)

        } else if (!commonServices.checkEmailValid(email)) {
            toast.warn(erros.INCORRECT_EMAIL);
            trackLogin(analyticsConstant.VALIDATION_ERROR, erros.INCORRECT_EMAIL)

        } else if (!password) {
            toast.warn(erros.PASSWORD_REQUIRED)
            trackLogin(analyticsConstant.VALIDATION_ERROR, erros.PASSWORD_REQUIRED)

        } else if (email && password) {
            setLoading(true);
            await ApiHelper.login(formData).then((response) => {
                analytics.setUser(response.data);
                setLoading(false);

                if (response.isSuccess === true) {
                    trackLogin("SUCCESS")
                    if (response.data !== null) {
                        commonServices.storeLocalData(storageConstants.AUTH, response.data.token);
                        commonServices.storeLocalData(storageConstants.USER_ROLE, response.data.role_type);
                        commonServices.storeLocalData(storageConstants.PROFILE, response.data.profile);
                        setIsHeaderUpdate(!isHeaderUpdate);
                        var nextRedirect = null;
                        if (response.data.is_profile_completed && response.data.is_mobile_verified) {
                            setIsHeaderUpdate(!isHeaderUpdate);
                            onClose();
                            if (response?.data?.user_unread_messages > 0) {
                                updateHeaderMessageIcon(UNREAD);
                            } else {
                                updateHeaderMessageIcon(READ);
                            }
                        }
                        if (!response.data.is_profile_completed) {
                            setCurrentStep(1);
                            setShowStep(true);
                            onClose();
                            navigate('/onboarding', {
                                state: {
                                    currentStep: 1,
                                    selectedProfile: response?.data?.role_type === 'EV' ? 'Evaluator' : 'Candidate',
                                    profile: response.data.profile,
                                    isHeaderUpdate: isHeaderUpdate,
                                    showStep: true,
                                    showModalApplyJob,
                                    referredData,
                                }
                            });

                            return;
                        } else if (!response.data.profile.email_verified_at) {
                            if (response.data.profile.role_type === 'CA' || response.data.profile.role_type === 'CU') {
                                onClose();
                                navigate('/register?email=' + response.data.profile.email);
                                return;
                            }
                            setCurrentStep(2);
                            setShowStep(true);
                            onClose();
                            navigate('/onboarding', {
                                state: {
                                    currentStep: 2,
                                    profile: response.data.profile,
                                    isHeaderUpdate: isHeaderUpdate,
                                    showStep: true,
                                    showModalApplyJob,
                                    referredData,
                                }
                            });
                            return;
                        } else if (!response.data.is_mobile_verified) {
                            nextRedirect = '/workprofile?profileInProgress=1';
                        } else if (response.data.token && (response.data.role_type === 'CD' || response.data.role_type === 'EV') && response.data.profile !== null && response.data.profile.progress < 100) {
                            setShowModalApplyJob(response?.data?.profile?.show_apply_popup);
                            setReferredData(response?.data?.profile?.referral_data);
                            nextRedirect = '/workprofile?profileInProgress=1';
                        } else if (response.data.token && response.data.role_type === 'EV' && response.data.profile !== null && response.data.profile.progress >= 100) {
                            nextRedirect = '/evaluator/dashboard';
                        } else if (response.data.token && response.data.role_type === 'CA') {
                            nextRedirect = '/company-dashboard';
                        } else if (response.data.token && response.data.role_type === 'PA') {
                            nextRedirect = '/company/edit';
                        }
                        if (redirect !== null) {
                            navigate(redirect);
                            // window.location.reload(false)
                        }else if(nextRedirect !== null){
                            navigate(nextRedirect);
                        } else {
                            // window.location.reload(false)
                            navigate('/',
                                {
                                    state: {
                                        "nextStep": response.data.next_step,
                                        "message": response.data.message
                                    }
                                }
                            );
                        }
                    }
                }
                else {
                    if (response.code === 203) {
                        setShowModalRestore(!showModalRestore);
                        trackLogin("RESTORE_POPUP")
                    } else if (response.code === 201) {
                        setShowModalError(true);
                        setModalErrorData({
                            ...modalErrorData,
                            'heading': "Account Deleted",
                            'paragraph': response.message,
                            'buttonText': BUTTON_TEXT_OK,
                        });
                    } else {
                        toast.warn(response.message);
                        if (response.data !== undefined && (response.data.length > 0 || response.data.length === undefined)) {
                            if (response.data.show_email_screen) {
                                if (response.data.profile.role_type === 'CA' || response.data.profile.role_type === 'CU') {
                                    onClose();
                                    navigate('/register?email=' + response.data.profile.email);
                                    return;
                                }
                                setCurrentStep(3);
                                setShowStep(true);
                                onClose();
                                navigate('/onboarding', {
                                    state: {
                                        currentStep: 3,
                                        profile: response.data.profile,
                                        isHeaderUpdate: isHeaderUpdate,
                                        showStep: true,
                                        showModalApplyJob,
                                        referredData,
                                    }
                                });
                                return

                            } else if (!response.data.is_profile_completed) {
                                setCurrentStep(1);
                                setShowStep(true);
                                onClose();

                                navigate('/onboarding', {
                                    state: {
                                        currentStep: 1,
                                        selectedProfile: response?.data?.role_type === 'EV' ? 'Evaluator' : 'Candidate',
                                        profile: response.data.profile,
                                        isHeaderUpdate: isHeaderUpdate,
                                        showStep: true,
                                        showModalApplyJob,
                                        referredData,
                                    }
                                });
                                return;
                            } else if (!response.data.profile.email_verified_at) {
                                onClose();
                                navigate('/register?email=' + response.data.profile.email);
                                return;
                                // if (response.data.profile.role_type === 'CA' || response.data.profile.role_type === 'CU') {
                                //     onClose();
                                //     navigate('/register?email=' + response.data.profile.email);
                                //     return;
                                // }

                                // setCurrentStep(2);
                                // setShowStep(true);
                                // onClose();
                                // navigate('/onboarding', {
                                //     state: {
                                //         currentStep: 2,
                                //         profile: response.data.profile,
                                //         isHeaderUpdate: isHeaderUpdate,
                                //         showStep: true,
                                //         showModalApplyJob,
                                //         referredData,
                                //     }
                                // });
                                // return;

                            } else if (!response.data.is_mobile_verified) {
                                setCurrentStep(3);
                                setShowStep(true);
                                onClose();
                                navigate('/onboarding', {
                                    state: {
                                        currentStep: 3,
                                        profile: response.data.profile,
                                        isHeaderUpdate: isHeaderUpdate,
                                        showStep: true,
                                        showModalApplyJob,
                                        referredData,
                                    }
                                });
                                return;
                            } else {
                                trackLogin(analyticsConstant.FAILED, response.message)
                            }
                        }
                    }

                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                toast.warn(error.message);
                trackLogin(analyticsConstant.FAILED, error.message)

            })


        } else {
            toast.warn(erros.EMAIL_PASSWORD_REQUIRED);
            trackLogin(analyticsConstant.VALIDATION_ERROR, erros.EMAIL_PASSWORD_REQUIRED)

        }

    }

    const restoreAccount = async (e) => {
        e.preventDefault();

        if (!email) {
            toast.warn(erros.EMAIL_REQUIRED)
        } else if (!password) {
            toast.warn(erros.PASSWORD_REQUIRED)
        } else if (email && password) {
            setLoading(true);
            setShowModalRestore(!showModalRestore);
            await ApiHelper.restoreAccount(formData).then((response) => {
                setLoading(false);

                if (response.isSuccess === true) {
                    toast.success(response.message);
                }
                else {
                    toast.warn(response.message);

                }
            });

        } else {
            toast.warn(erros.EMAIL_PASSWORD_REQUIRED);
        }

    }

    useEffect(() => {

        setShowStep(false);
        var options = { hideAfter: 5 };
        if (isVerified !== null) {
            commonServices.clearLocalStorageData();
            toast.success('Email verified successfully!', options);
            navigate('/?auth=login');
        }

        if (msg !== null) {
            setShowModalError(true);
            setModalErrorData({
                ...modalErrorData,
                'heading': "Registration Successful, please verify your email",
                'paragraph': msg,
                'buttonText': BUTTON_TEXT_OK,
            });
        }
        if (verifyUrl !== null) {
            var verifyLink = verifyUrl + "&hash=" + verifyUrlHash + "&signature=" + verifyUrlSignature;
            setLoading(true);
            ApiHelper.emailVerification(verifyLink).then((response) => {
                setLoading(false);
                if (response.isSuccess === true) {
                    toast.success(response.message);
                    navigate('/?auth=login');
                }
                else {
                    toast.warn(response.message);
                    navigate('/?auth=login');
                }
            });
        }
    }, [])


    const [searchParams, setSearchParams] = useSearchParams();

    const switchForgotPassword = (event) => {
        setSearchParams({ "auth": "forgot" })
    }

    const switchRegister = (event) => {
        setSearchParams({ "auth": "register" })
    }

    var auth = commonServices.getLocalData(storageConstants.AUTH);

    const submitRef = useRef(null);

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                submitRef.current.click();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    return (
        <div className='auth-container-content-wrapper'
        >
            {
                loading && <Loader />
            }
            <img src={logo} className='logo' />

            <p className='title'
            >
                Log in to TrueTalent
            </p>

            <form className='form-wrapper'>
                <Input type="email" isRequired={true} label="Email" placeholder="Email Address" id="LoginEmail" onChange={(e) => setEmail(e.target.value)}
                    value={email} />
                <Input type={passwordShown ? "text" : "password"} isRequired={true} label="Password" placeholder="Password" id="" onChange={(e) => setPassword(e.target.value)} value={password} />

                <div className='remember-forget-password'>
                    <Checkbox label="Remember Me" />
                    <Link
                        type="a"
                        text="Forgot Password?"
                        className="green-link"
                        onClick={switchForgotPassword}
                    />
                </div>

                <Button
                    buttonRef={submitRef}
                    className="btn big tt-btn__primary py-2"
                    text="Sign In"
                    type="submit"
                    buttonType="primary"
                    onClick={e => changeHome(e)}
                />

                {/* <div style={{ width: "100%" }}>
                    <Button
                        className="d-flex align-items-center w-100 justify-content-center py-2 btn big tt-btn__light flex-row-reverse"
                        buttonType="light"
                        text={`Sign in with Google`}
                        buttonIcon={<IconResource className="me-2" fontSize="24px" type={"google"} />}
                        onClick={(e) => {
                            getGoogleLogin(e)
                            trackLogin("GOOGLE_LOGIN")
                        }}
                    />
                    <Text type="small" className='text-center mt-2' text="(For Jobseekers only)" />
                </div> */}

                <div
                    className='d-flex align-items-center justify-content-center'

                ><Text type="small" text="Don't have an account?" />
                    <Link
                        type="a"
                        text="Sign Up"
                        className="green-link green-link-big ms-1"
                        onClick={switchRegister}
                    > </Link>
                </div>

            </form>



        </div>
    )
}

export default Login