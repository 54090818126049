/* eslint-disable */
import ModalAddEducation from "../Modals/ModalAddEducation";
import ModalCertificate from "../Modals/ModalCert";
import ModalEmp from "../Modals/ModalEmp";
import ModalAward from "../Modals/ModalAward";
import ModalSkills from "../Modals/ModalSkills";
import ModalInformation from "../Modals/ModalInformation";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import * as commonServices from "@/services/common";
import defaultAvatar from "@/Assets/svgs/default_avatar.svg";
import "react-phone-number-input/style.css";
import { COUNTRY } from "@/constants/storageConstants";
import { redirectBack } from "@/utils/redirectUtil";
import Editor from "../Common/Editor";
import ModalWorkProfilePreview from "../Modals/ModalWorkProfilePreview";
import ModalShare from "../Modals/ModalShare";
import AddSkill from "../Register/RegistrationSteps/Steps/AddSkill";
import AddLinks from "../Register/RegistrationSteps/Steps/AddLinks";
import { isCandidate } from "@/utils/authUtil";
import Text from "../_widgets/Text/Text";
import Link from "../_widgets/Link/Link";
import whyVideoImage from "../../Assets/images/why-video.png";
import Input from "../_widgets/Input/Input";
import Button from "../_widgets/Button/Button";
import Dropdown from "../_widgets/Dropdown/Dropdown";
import Progress from "../_widgets/ProgressBar/ProgressBar";
import Upload from "../_widgets/Upload/Upload";
import arrowLeft from "@/Assets/svgs/arrow-left.svg";
import { Modal } from "react-bootstrap";
import { warn } from "../../wrapper/toast";
import { SELECT_VIDEO_FILE } from "../../constants/stringConstants";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--green);
  left: calc(50% - 75px);
  position: fixed;
`;

function WorkProfileLayout({
  profile,
  setProfile,
  loading,
  showModalEducation,
  setShowModalEducation,
  showModalEmployment,
  setShowModalEmployment,
  showModalCertificate,
  setShowModalCertificate,
  showModalSkills,
  setShowModalSkills,
  showModalAward,
  setShowModalAward,
  setDataAutoSave,
  skillData,
  setSkillData,
  showExperienceSuggestionModal,
  setShowExperienceSuggestionModal,
  experienceSuggestionModalData,
  setExperienceSuggestionModalData,
  showPreviewModal,
  setShowPreviewModal,
  profilePreview,
  updateWorkProfile,
  showModalShare,
  setShowModalShare,
  fileRef,
  currentLocation,
  locationSuggestion,
  masterData,
  summary,
  updateSummary,
  years,
  months,
  skill,
  setSkill,
  isOpen,
  closeModal,
  color,
  links,
  setLinks,
  normalizePhoneNumber1,
  dropdownOptions,
  onChange,
  locationHandler,
  onLocationClick,
  setLocationSuggestion,
  experienceHandler,
  sortArray,
  month,
  deleteEmployment,
  deleteDegree,
  deleteCertificate,
  deleteAward,
  uploadMedia,
  deleteResume,
  onOpen,
  navigate,
  triggerSelect,
  triggerClickDown,
  skillChildRef,
}) {
  return (
    <>
      {loading ? (
        <div className="loader">
          <ClipLoader color={color} loading={true} css={override} size={150} />
        </div>
      ) : null}

      <ModalAddEducation
        showModal={showModalEducation}
        setShowModal={setShowModalEducation}
        profile={profile}
        setProfile={setProfile}
        setDataAutoSave={setDataAutoSave}
      />
      <ModalEmp
        showModal={showModalEmployment}
        setShowModal={setShowModalEmployment}
        profile={profile}
        setProfile={setProfile}
        setDataAutoSave={setDataAutoSave}
      />
      <ModalCertificate
        showModal={showModalCertificate}
        setShowModal={setShowModalCertificate}
        profile={profile}
        setProfile={setProfile}
        setDataAutoSave={setDataAutoSave}
      />
      <ModalSkills
        showModal={showModalSkills}
        setShowModal={setShowModalSkills}
        profile={profile}
        setProfile={setProfile}
        skillData={skillData}
        setSkillData={setSkillData}
        setDataAutoSave={setDataAutoSave}
        setShowExperienceSuggestionModal={setShowExperienceSuggestionModal}
        setExperienceSuggestionModalData={setExperienceSuggestionModalData}
      />
      <ModalAward
        showModal={showModalAward}
        setShowModal={setShowModalAward}
        profile={profile}
        setProfile={setProfile}
        setDataAutoSave={setDataAutoSave}
      />
      <ModalInformation
        showModal={showExperienceSuggestionModal}
        setShowModal={setShowExperienceSuggestionModal}
        title={experienceSuggestionModalData?.heading}
        text={experienceSuggestionModalData?.paragraph}
        buttonOneText={experienceSuggestionModalData?.buttonOneText}
        buttonOneAction={experienceSuggestionModalData?.buttonOneAction}
        buttonTwoText={experienceSuggestionModalData?.buttonTwoText}
        buttonTwoAction={experienceSuggestionModalData?.buttonTwoAction}
      />
      <ModalWorkProfilePreview
        showModal={showPreviewModal}
        setShowModal={setShowPreviewModal}
        profile={profilePreview}
        updateWorkProfile={updateWorkProfile}
      />
      <ModalShare
        showModal={showModalShare}
        setShowModal={setShowModalShare}
        title={profile?.full_name}
        url={window.location.origin + "/workprofile/view/" + profile?.id}
      />

      <div className="work-profile tt-container">
        <div className="work-profile-progress">
          <div className="row work-profile-progress-content gap-3 gap-md-0 ">
            <div className="col-12 col-sm-12  col-lg-3 col-xl-3">
              <div className="main_t mb-0 mb-md-3">
                <Link
                  type="a"
                  className="hand-hover"
                  text={`<img className="back-arrow" src=${arrowLeft}></img>`}
                  onClick={() => redirectBack()}
                ></Link>
                <Text
                  type="h2"
                  className="mb-0"
                  text={isCandidate() ? "My Work Profile" : "My Profile"}
                />
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4">
              <Progress
                progress={profile.progress}
                background="green"
                height="10px"
                type="with-text"
                text={"Profile Completeness"}
              />
            </div>

            <div className="col-12 col-md-7 col-lg-4 col-xl-5">
              <div className="form-buttons">
                {isCandidate() ? (
                  <Button
                    buttonType="secondary"
                    text="Share"
                    onClick={() => {
                      setShowModalShare(true);
                    }}
                  />
                ) : null}
                <Button
                  buttonType="secondary"
                  text="Cancel"
                  onClick={() => {
                    redirectBack();
                  }}
                />
                {isCandidate() ? (
                  <Button
                    buttonType="primary"
                    text="Preview & Save"
                    onClick={() => updateWorkProfile("preview")}
                  />
                ) : (
                  <Button
                    buttonType="primary"
                    type="submit"
                    text="Save"
                    onClick={() => updateWorkProfile("save")}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="work-profile-content">
          <div className="work-profile-main">
            <div className="personal-details">
              <Text text="Personal Details" type="h2" />
              <Upload
                type="strip"
                fileLink={profile?.user_work_profile?.cv_link}
                text="Resume"
                fileRef={fileRef}
                accept="image/*, .doc, .pdf, .docx, .ppt, .pptx"
                onChange={(e) => uploadMedia(e, "resume")}
                onDelete={() => {
                  deleteResume();
                }}
                helpTextPosition="left"
                helpText="Don't have a resume?"
                helpTextNavigate="Use TrueTalent Resume Maker"
                helpTextNavigateClick={() => {
                  navigate("/resume-builder");
                }}
              />

              {isCandidate() ? (
                <Upload
                  type="strip"
                  fileLink={profile?.user_work_profile?.video_link}
                  text="Video"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={e => {
                    if (e?.target?.files[0]?.type?.startsWith('video/')) {
                      uploadMedia(e, "video");
                    } else {
                      warn(SELECT_VIDEO_FILE);
                    }
                  }}
                  onDelete={() => {
                    deleteResume("video_link");
                  }}
                  helpTextPosition="right"
                  helpTextNavigate="Why?"
                  helpTextNavigateClick={() => {
                    onOpen();
                  }}
                />
              ) : null}

              <div className="d-flex align-items-center profile-upload">
                <div className="profile-img img-border">
                  <img
                    onError={commonServices.imgError}
                    src={profile.avatar_location ?? defaultAvatar}
                    alt=""
                  />
                </div>
                <Upload
                  text="Upload photo"
                  accept="image/*"
                  onChange={(e) => uploadMedia(e, "profile")}
                />
              </div>
            </div>

            <div className="work-profile-form">
              <form action="">
                <div className="row mb-3 gap-2 gap-sm-0">
                  <div className="col-12 col-sm-6">
                    <Input
                      type="text"
                      id="first_name"
                      placeholder="First Name"
                      isRequired={true}
                      value={profile.first_name ? profile.first_name : ""}
                      name="first_name"
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div className="col-12 col-sm-6">
                    <Input
                      type="text"
                      id="last_name"
                      placeholder="Last Name"
                      isRequired={true}
                      value={profile.last_name ? profile.last_name : ""}
                      name="last_name"
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>

                <div className="row mb-3 gap-2 gap-sm-0">
                  <div className="col-12 col-sm-6">
                    <Input
                      type="text"
                      isRequired="true"
                      disabled
                      readOnly
                      className="form-control"
                      placeholder="Email"
                      value={profile.email ? profile.email : ""}
                      name="email"
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div className="col-12 col-sm-6">
                    <Input
                      type="phone"
                      label="Phone Number"
                      placeholder="Enter phone number"
                      id="contact_number"
                      name="contact_number"
                      defaultCountry={COUNTRY}
                      value={profile.user_work_profile && profile.phone_number ? normalizePhoneNumber1(profile.phone_number) : ''}
                      onChange={(phoneNumber) => {
                        var tempProfile = { ...profile };
                        if (tempProfile['user_work_profile'] === null) {
                          tempProfile['user_work_profile'] = [];
                        }

                        if (typeof tempProfile['user_work_profile'] !== 'undefined') {
                          tempProfile['phone_number'] = phoneNumber;
                        }
                        setProfile(tempProfile);
                      }}
                    />
                  </div>
                </div>
                {isCandidate() ?
                  (<div className="row mb-3 gap-2 gap-sm-0">
                    <div className="col-12 col-sm-6">
                      <Dropdown
                        type="suggestonWithIcon"
                        suggestionArray={locationSuggestion}
                        showHelpText={true}
                        onSuggestionClick={(suggestion) => { console.log("suggestion-->", suggestion); onLocationClick(suggestion); document.getElementById('search_location').value = ''; setLocationSuggestion([]) }}
                        label={"Location"}
                        placeholder={"Location"}
                        icon="mapPin" id="search_location" value={currentLocation} autoComplete='off'
                        name="location_id"
                        onChange={(e) => { locationHandler(e.target.value) }}
                        onKeyPress={(e) => { triggerSelect(e) }}
                        onKeyDown={(e) => triggerClickDown(e)}
                      />
                    </div>

                    <div className="col-12 col-sm-6">
                      <Dropdown
                        isRequired={true}
                        showDefaultOption={true}
                        label={"Joining Preference"}
                        id="joining_preference_id"
                        name="joining_preference_id"
                        value={
                          profile.user_work_profile &&
                            profile.user_work_profile.joining_preference_id
                            ? profile.user_work_profile.joining_preference_id
                            : ""
                        }
                        onChange={(e) => onChange(e, "user_work_profile")}
                        select={masterData?.joining_preferences}
                      />
                    </div>
                  </div>
                  ) : null}
                {isCandidate() ? (
                  <>
                    <div className="row mb-3 gap-2 gap-sm-0">
                      <div className="col">
                        <Input
                          type="text"
                          isRequired="true"
                          className="form-control"
                          label="Job Title"
                          placeholder="Title"
                          value={
                            profile?.user_work_profile?.title
                              ? profile?.user_work_profile?.title
                              : ""
                          }
                          name="title"
                          onChange={(e) => onChange(e, "user_work_profile")}
                        />
                      </div>
                    </div>
                    <div className="row mb-3 gap-2 gap-sm-0">
                      <div className="col-12 col-sm-6">
                        <Dropdown
                          isRequired={false}
                          label={"Impacted by layoff"}
                          id="layoff"
                          name="layoff"
                          value={
                            profile.user_work_profile &&
                              profile.user_work_profile.layoff
                              ? profile.user_work_profile.layoff
                              : ""
                          }
                          onChange={(e) => onChange(e, "user_work_profile")}
                          select={dropdownOptions}
                        />
                      </div>

                      <div className="col-12 col-sm-6">
                        <Dropdown
                          isRequired={false}
                          label={"Her Career Reboot"}
                          id="her_career_reboot"
                          name="her_career_reboot"
                          value={
                            profile.user_work_profile &&
                              profile.user_work_profile.her_career_reboot
                              ? profile.user_work_profile.her_career_reboot
                              : ""
                          }
                          onChange={(e) => onChange(e, "user_work_profile")}
                          select={dropdownOptions}
                          icon={"info"}
                          iconClass={"hand-hover"}
                          iconTitle={
                            "For women: Restarting a career after a break."
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-3 gap-2 gap-sm-0">
                      <div className="col-12 col-sm-6">
                        <Dropdown
                          isRequired={false}
                          label={"Differently Abled"}
                          id="differently_abled"
                          name="differently_abled"
                          value={
                            profile.user_work_profile &&
                              profile.user_work_profile.differently_abled
                              ? profile.user_work_profile.differently_abled
                              : ""
                          }
                          onChange={(e) => onChange(e, "user_work_profile")}
                          select={dropdownOptions}
                        />
                      </div>

                      <div className="col-12 col-sm-6">
                        <Dropdown
                          isRequired={false}
                          label={"Armed Forces"}
                          id="armed_forces"
                          name="armed_forces"
                          value={
                            profile.user_work_profile &&
                              profile.user_work_profile.armed_forces
                              ? profile.user_work_profile.armed_forces
                              : ""
                          }
                          onChange={(e) => onChange(e, "user_work_profile")}
                          select={dropdownOptions}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </form>

              <div className="work-profile-sections">
                <div className="row mb-3">
                  <div className="col">
                    <Text
                      type="h2"
                      text="Professional Summary"
                      className="required"
                    />
                    <Text
                      type="h7"
                      className="mb-1"
                      text="Write 2-4 short sentences that best showcases your experience, knowledge, skills and competencies."
                    ></Text>
                    <Editor
                      desc={summary}
                      setDesc={updateSummary}
                      placeholder="Enter Professional Summary"
                    />
                  </div>
                </div>

                <div className="mb-3" id="Experience">
                  <Text type="h2" text="Total Experience" />
                  <div className="row gap-2 gap-sm-0 ">
                    <div className="col-12 col-sm-6">
                      <Dropdown
                        isRequired={false}
                        label={"Years"}
                        id="year"
                        name="year"
                        value={profile.user_work_profile ? Math.trunc(profile.user_work_profile.total_experience / 12) : 0}
                        onChange={(e) => experienceHandler(e, "year")}
                        select={years}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <Dropdown
                        isRequired={false}
                        label={"Months"}
                        id="mon"
                        name="mon"
                        value={
                          profile.user_work_profile
                            ? profile.user_work_profile.total_experience % 12
                            : 0
                        }
                        onChange={(e) => experienceHandler(e, "month")}
                        select={months}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-3" id="Employment">
                  <div className="col">
                    <Text
                      type="h2"
                      text="Employment History"
                      className={
                        isCandidate() &&
                          profile?.user_work_profile?.total_experience > 0
                          ? "required"
                          : null
                      }
                    />
                    <Text
                      type="h7"
                      text="Give details about your employment over the years starting with your current employment. If you are starting your career, mention your career goals and aspirations, starting with, Looking forward to an exciting career."
                    ></Text>
                    {profile.userWorkProfileDetail &&
                      profile.userWorkProfileDetail.experience &&
                      sortArray(profile.userWorkProfileDetail.experience).map(
                        (data, key) => {
                          return (
                            <div className="emp_box" key={key}>
                              <div
                                className="emp_box_det"
                                onClick={(e) => {
                                  setShowModalEmployment((prev) => !prev);
                                }}
                              >
                                <div>
                                  <p>{data.awarded_by}</p>
                                  <p className="green_text">{data.title}</p>
                                  <p>
                                    {month[data.from_date.split("/")[0] * 1]}
                                    {data.from_date.split("/")[2]}
                                    <span>-</span>
                                    {data.to_date !== null ? (
                                      <>
                                        {month[data.to_date.split("/")[0] * 1]}
                                        {data.to_date.split("/")[2]}
                                      </>
                                    ) : (
                                      <>Present</>
                                    )}
                                  </p>
                                </div>
                                <img
                                  onError={commonServices.imgError}
                                  src="./Assets/svgs/chevron_down.svg"
                                  alt=""
                                />
                              </div>
                              <button onClick={(e) => deleteEmployment(e, key)}>
                                <img
                                  onError={commonServices.imgError}
                                  src="./Assets/svgs/trash.svg"
                                  alt=""
                                  className="trash"
                                />
                              </button>
                            </div>
                          );
                        }
                      )}
                    <button
                      className="add-btn"
                      onClick={() => {
                        setShowModalEmployment((prev) => !prev);
                      }}
                    >
                      <img
                        onError={commonServices.imgError}
                        src="./Assets/svgs/plus_circle.svg"
                        alt=""
                      />
                      Add
                      {profile.userWorkProfileDetail &&
                        profile.userWorkProfileDetail.experience &&
                        profile.userWorkProfileDetail.experience.length > 0
                        ? "/Edit"
                        : ""}
                      Employment History
                    </button>
                  </div>
                </div>

                <div>
                  <AddSkill
                    skill={skill}
                    setSkill={setSkill}
                    setShowExperienceSuggestionModal={
                      setShowExperienceSuggestionModal
                    }
                    profile={profile}
                    showCerticate={true}
                    ref={skillChildRef}
                  />
                </div>

                <div>
                  <AddLinks links={links} setLinks={setLinks} />
                </div>

                <div className="row mb-3" id="Education">
                  <div className="col">
                    <Text type="h2" text="Education" className={"required"} />
                    <Text
                      type="h7"
                      text="Highlight your education details with details of your specific areas of learning"
                    ></Text>

                    {profile.userWorkProfileDetail &&
                      profile.userWorkProfileDetail.degree &&
                      sortArray(profile.userWorkProfileDetail.degree).map(
                        (data, key) => {
                          return (
                            <div className="emp_box" key={key}>
                              <div
                                className="emp_box_det"
                                onClick={(e) => {
                                  setShowModalEducation((prev) => !prev);
                                }}
                              >
                                <div>
                                  <p>{data.awarded_by}</p>
                                  <p className="green_text">{data.title}</p>
                                  <p>
                                    {data.from_date !== null
                                      ? month[data.from_date.split("/")[0] * 1]
                                      : ""}
                                    {data.from_date !== null
                                      ? data.from_date.split("/")[2] + " - "
                                      : ""}
                                    {data.to_date !== null ? (
                                      <>
                                        {month[data.to_date.split("/")[0] * 1]}
                                        {data.to_date.split("/")[2]}
                                      </>
                                    ) : (
                                      <>Present</>
                                    )}
                                  </p>
                                </div>
                                <img
                                  onError={commonServices.imgError}
                                  src="./Assets/svgs/chevron_down.svg"
                                  alt=""
                                />
                              </div>
                              <button onClick={(e) => deleteDegree(e, key)}>
                                <img
                                  onError={commonServices.imgError}
                                  src="./Assets/svgs/trash.svg"
                                  alt=""
                                  className="trash"
                                />
                              </button>
                            </div>
                          );
                        }
                      )}

                    <button
                      className="add-btn"
                      onClick={() => {
                        setShowModalEducation((prev) => !prev);
                      }}
                    >
                      <img
                        onError={commonServices.imgError}
                        src="./Assets/svgs/plus_circle.svg"
                        alt=""
                      />
                      Add
                      {profile.userWorkProfileDetail &&
                        profile.userWorkProfileDetail.degree &&
                        profile.userWorkProfileDetail.degree.length > 0
                        ? "/Edit"
                        : ""}
                      Education
                    </button>
                  </div>
                </div>

                <div className="row mb-3" id="Certification">
                  <div className="col">
                    <Text type="h2" text="Certifications" />
                    <Text
                      type="h7"
                      text="Show your relevant experience (last 10 years). Use bullet points to note your achievements"
                    ></Text>
                    {profile.userWorkProfileDetail &&
                      profile.userWorkProfileDetail.certificate &&
                      sortArray(profile.userWorkProfileDetail.certificate).map(
                        (data, key) => {
                          return (
                            <div className="emp_box" key={key}>
                              <div
                                className="emp_box_det"
                                onClick={(e) => {
                                  setShowModalCertificate(true);
                                }}
                              >
                                <div>
                                  <p>{data.awarded_by}</p>
                                  <p className="green_text">{data.title}</p>
                                  <p>
                                    {month[data.from_date.split("/")[0] * 1]}
                                    {data.from_date.split("/")[2]}
                                  </p>
                                </div>
                                <img
                                  onError={commonServices.imgError}
                                  src="./Assets/svgs/chevron_down.svg"
                                  alt=""
                                />
                              </div>
                              <button
                                onClick={(e) => deleteCertificate(e, key)}
                              >
                                <img
                                  onError={commonServices.imgError}
                                  src="./Assets/svgs/trash.svg"
                                  alt=""
                                  className="trash"
                                />
                              </button>
                            </div>
                          );
                        }
                      )}
                    <button
                      className="add-btn"
                      onClick={() => {
                        setShowModalCertificate(true);
                      }}
                    >
                      <img
                        onError={commonServices.imgError}
                        src="./Assets/svgs/plus_circle.svg"
                        alt=""
                      />
                      Add
                      {profile.userWorkProfileDetail &&
                        profile.userWorkProfileDetail.certificate &&
                        profile.userWorkProfileDetail.certificate.length > 0
                        ? "/Edit"
                        : ""}
                      Certification
                    </button>
                  </div>
                </div>

                <div className="row mb-3" id="Awards">
                  <div className="col">
                    <Text type="h2" text="Awards and Accomplishments" />
                    <Text
                      type="h7"
                      text="Show your relevant awards & accomplishments (last 10 years). Use bullet points to note your achievements"
                    ></Text>

                    {profile.userWorkProfileDetail &&
                      profile.userWorkProfileDetail.award &&
                      sortArray(profile.userWorkProfileDetail.award).map(
                        (data, key) => {
                          return (
                            <div className="emp_box" key={key}>
                              <div
                                className="emp_box_det"
                                onClick={(e) => {
                                  setShowModalAward(true);
                                }}
                              >
                                <div>
                                  <p>{data.awarded_by}</p>
                                  <p className="green_text">{data.title}</p>
                                  <p>
                                    {month[data.from_date.split("/")[0] * 1]}
                                    {data.from_date.split("/")[2]}
                                  </p>
                                </div>
                                <img
                                  onError={commonServices.imgError}
                                  src="./Assets/svgs/chevron_down.svg"
                                  alt=""
                                />
                              </div>
                              <button
                                onClick={(e) => {
                                  deleteAward(e, key);
                                }}
                              >
                                <img
                                  onError={commonServices.imgError}
                                  src="./Assets/svgs/trash.svg"
                                  alt=""
                                  className="trash"
                                />
                              </button>
                            </div>
                          );
                        }
                      )}

                    <button
                      className="add-btn"
                      onClick={() => {
                        setShowModalAward(true);
                      }}
                    >
                      <img
                        onError={commonServices.imgError}
                        src="./Assets/svgs/plus_circle.svg"
                        alt=""
                      />
                      Add
                      {profile.userWorkProfileDetail &&
                        profile.userWorkProfileDetail.award &&
                        profile.userWorkProfileDetail.award.length > 0
                        ? "/Edit"
                        : ""}
                      Awards and Accomplishments
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=""></div>
        </div>
      </div>

      <Modal backdrop="static" show={isOpen} onHide={closeModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Why share your intro video?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "80vh", overflowY: "scroll" }}>
          <div className="d-flex flex-column gap-2 align-items-start text-start">
            <Text
              type="small"
              text="We value the uniqueness of each candidate and want to give you the opportunity to stand out. In this short introductory video, you can showcase your personality, passion, and skills directly to potential recruiters."
            />

            <Text
              type="h2"
              text="Why an Introductory Video?"
              textAlign={"left"}
            />

            <Text
              text="Recruiters often receive numerous applications, and an introductory video helps you break through the noise. It's a chance to highlight what makes you a great fit for a company beyond what's on your resume. Your video can be a game-changer in leaving a positive first impression."
              type="small"
            />

            <img
              height="350px"
              className="m-auto"
              src={whyVideoImage}
              alt="video"
            />

            <div>
              <Text text="What to Include:" type="h2" />
              <Text
                text="<strong>Brief Introduction: </strong> Share your name and your background."
                type="small"
              />
              <Text
                text="<strong>Show Your Personality:  </strong> Let your enthusiasm shine through. Be genuine and authentic."
                type="small"
              />
              <Text
                text="<strong> Key Skills: </strong> Highlight your key skills and experiences"
                type="small"
              />
            </div>

            <div>
              <Text
                marginBottom={"10px"}
                text="Video Guidelines:"
                textAlign={"left"}
                type="h2"
              />
              <Text
                text="<strong>Keep it concise: </strong> Aim for a video length of 60 seconds or less."
                type="small"
              />
              <Text
                text="<strong>Quality matters:  </strong> Make sure your video has clear audio and video quality."
                type="small"
              />
              <Text
                text="<strong> Be professional: </strong> Dress appropriately and choose a neat background."
                type="small"
              />
            </div>

            <div>
              <Text
                text="How to Upload?"
                textAlign={"left"}
                type="h2"
                fontWeight={500}
              />

              <Text
                type="small"
                text="Record your video using your smartphone or webcam.Save it in a common format (MP4, MOV).Click the 'Submit an introductory video' button on your profile page.Select your video file and wait for it to upload."
              />

              <Text
                type="small"
                text="Remember, this is your chance to make a memorable impression. Be confident, be yourself, and showcase your potential!"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WorkProfileLayout;
